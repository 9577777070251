import React, { Component } from 'react';
import { Button } from 'reactstrap';
import * as Icon from 'react-feather';

export class Camera extends Component {
    /**
     * Processes available devices and identifies one by the label
     * @memberof Camera
     * @instance
     */
    processDevices(devices) {
        devices.forEach(device => {
            console.log(device.label);
            this.setDevice(device);
        });
    }

    /**
     * Sets the active device and starts playing the feed
     * @memberof Camera
     * @instance
     */
    async setDevice(device) {
        const { deviceId } = device;
        const stream = await navigator.mediaDevices.getUserMedia({ audio: false, video: { deviceId } });
        this.videoPlayer.srcObject = stream;
        this.videoPlayer.play();
    }

    /**
     * On mount, grab the users connected devices and process them
     * @memberof Camera
     * @instance
     * @override
     */
    async componentDidMount() {
        const cameras = await navigator.mediaDevices.enumerateDevices();
        this.processDevices(cameras);
    }

    /**
     * Handles taking a still image from the video feed on the camera
     * @memberof Camera
     * @instance
     */
    takePhoto = () => {
        const { sendFile } = this.props;
        const context = this.canvas.getContext('2d');
        context.drawImage(this.videoPlayer, 0, 0, (window.innerWidth - 40), (window.innerHeight - 600));
        this.canvas.toBlob(sendFile);
    };

    render() {
        const winWidth = (window.innerWidth - 40)
        const winHeight = (window.innerHeight - 600)

        return (
            <div className="c-camera-feed">
                <div className="c-camera-feed__viewer">
                    <video ref={ref => (this.videoPlayer = ref)} width={winWidth} height={winHeight} />
                </div>
                <Button color="info" size="lg" block onClick={this.takePhoto}>
                    <Icon.Aperture color="white" size={20} className="icon-navbar" /> Capturar foto
                </Button>
                {/* 
                <button onClick={this.takePhoto}>Take photo!</button>
                <div className="c-camera-feed__stage">
                    <canvas width={winWidth} height={winHeight} ref={ref => (this.canvas = ref)} />
                </div> */}
            </div>
        );
    }
}