import React, { useEffect } from 'react';
import Sidebar from '../../components/side_menu';

export default function Logout(props) {

    

    useEffect(() => {
        loadChamados();
    }, [])

    const loadChamados = () => {

        localStorage.setItem('user_login', '');
        localStorage.setItem('token', '');
        localStorage.clear();
        window.location.href = '/' 
        
    }

    return (
        <div>
            <Sidebar />
            <h5 className="display-4" style={{ fontSize: 28, textAlign: 'center', backgroundColor: '#e8eaed', marginBottom: '0%'}} >
               Saindo...
            </h5>
            
        </div>
    );
}
