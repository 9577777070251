import React, { Component, useState, useEffect } from 'react';
import * as Icon from 'react-feather';
import { Alert } from 'reactstrap';
import api from '../../services/api';
import './index.css';


const Statusbar = (props) => {
  const [online, setOnline] = useState(navigator.onLine);
  const [version, setVersion] = useState('1.5a');
  const [newversion, setNewversion] = useState(false);

  useEffect(() => {
    networkStatus();
    versionApp();
  }, [])

  const networkStatus = () => {
    setOnline(navigator.onLine)
    // console.log('navigator.online', navigator.onLine)
  }

  const versionApp = () => {

    api.post('/usuarios-pwa/app-version', {
      app_version: version,
    }, {
      headers: {
        Accept: 'application/json',
      }
    }).then(resposta => {
      if(version != resposta.data){
        setNewversion(resposta.data)
      }else{
        setNewversion(false)
      }
    });
    
  }



  return (
    <div>
        { !online && 
        <Alert color="danger" className="statusBar">
            <Icon.WifiOff size={20} color="black" /> Você está offline!
        </Alert>
        }
      {newversion &&
        <div>
          <Alert color="danger" className="statusBar">
            <Icon.AlertTriangle size={60} color="black" style={{ marginRight: 15 }} /> Nova atualização disponível! Desinstale o aplicativo e instale novamente para obter as alterações mais recentes.
          </Alert>
          <Alert color="danger" className="statusBar">
          <strong>Versão atual ({version}) atualize para versão ({newversion})</strong>
          </Alert>
          <Alert color="danger" className="statusBar">
            <a href="https://sisac.ciebe.com.br/login/instrucoes" target="_blank" >Instruções</a>
          </Alert>
        </div>
      }
    </div>
  );
}

export default Statusbar;