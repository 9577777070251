import React, { Component, useState, useEffect } from 'react';
import Sidebar from '../../components/side_menu';
import {
  Container, Table, Badge,
  Media, FormGroup, Label,
  Input, TabContent, TabPane,
  Nav, NavItem, NavLink,
  Card, Button, CardTitle,
  CardText, Row, Col,
  Modal, ModalBody, Collapse,
  ModalFooter, ListGroup, ListGroupItem,
  CardBody, ToastHeader, InputGroup, 
  InputGroupAddon, InputGroupText, ModalHeader
} from 'reactstrap';
import SweetAlert from 'sweetalert2-react';
import FileBase64 from 'react-file-base64';
import Select2 from 'react-select2-wrapper';
import chroma from 'chroma-js';
import {Camera} from '../../components/camera';
import * as FloatingButton from 'react-floating-action-button';
import ReactDOM from 'react-dom';
import * as Icon from 'react-feather';
// import MultiSelect from 'react-native-multiple-select';
import classnames from 'classnames';
import api from '../../services/api';
import './select2.css';
import  './style.css';

export default function VisualizarChamado(props) {

  const [activeTab, setActiveTab] = useState('1');
  const [chamado, setChamado] = useState([]);
  const [anexos, setAnexos] = useState([]);
  const [historico, setHistorico] = useState([]);
  const [observacao, setObservacao] = useState([]);
  const [visualizadores, setVisualizadores] = useState([]);
  const [selectvisualizadores, setSelectvisualizadores] = useState([]);
  const [visualizadoresadd, setVisualizadoresadd] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [modalDesc, setModalDesc] = useState(false);
  const [modalEncaminhar, setModalEncaminhar] = useState(false);
  const [modalObs, setModalObs] = useState(false);
  const [modalChat, setModalChat] = useState(false);
  const [modalVis, setModalVis] = useState(false);
  const [modalFin, setModalFinalizar] = useState(false);
  const [modalReprovado, setModalReprovado] = useState(false);
  const [modalFinalizarChamado, setModalFinalizarChamado] = useState(false);
  const [modalAltPra, setModalAlterarPrazo] = useState(false);
  const [modalRename, setRename] = useState(false);
  const { buttonLabelToastr } = props;
  const [showToastr, setShowToastr] = useState(false);
  
  const [optionsDepartamento, setoptionsDepartamento] = useState([]);
  const [selectedDepartamento, setselectedDepartamento] = useState([]);
  const [optionsAtendente, setoptionsAtendente] = useState([]);
  const [selectedAtendente, setselectedAtendente] = useState([]);

  const toggleToastr = () => setShowToastr(!showToastr);

  const [encaminharDescricao, setencaminharDescricao] = useState('');
  const [newNameFile, setNewNameFile] = useState('');
  const [prazoEncaminhar, setPrazoEncaminhar] = useState('');
  const [textoFinalizar, settextoFinalizar] = useState('');
  const [chatMessages, setChatMessages] = useState([]);
  const [textoObservacao, settextoObservacao] = useState('');
  const [textoReprovado, settextoReprovado] = useState('');
  const [textoFinalizarChamado, settextoFinalizarChamado] = useState('');
  const [textoChat, settextoChat] = useState('');
  const [prazoChamado, setprazoChamado] = useState('');
  const [obdsId, setObsId] = useState('');
  const [infoToastr, setinfoToastr] = useState([]);
  const [modalFile, setModalFile] = useState(false);
  const [modalCamera, setModalCamera] = useState(false);
  const [files, setFiles] = useState([]);
  const [infoRenameFile, setInfoRenameFile] = useState([]);

  // Lista Adicionar Visualizadores 
  const [visualizadoresNovos, setModalVisualizadoresNovos] = useState([]);

  const toggleModalDesc = () => setModalDesc(!modalDesc);
  const toggleModalObs = () => setModalObs(!modalObs);
  const toggleModalChat = () => setModalChat(!modalChat);
  const toggleFile = () => setModalFile(!modalFile);
  const toggleCamera = () => setModalCamera(!modalCamera);
  const toggleModalVis = () => setModalVis(!modalVis);
  const toggleModalFinalizar = () => setModalFinalizar(!modalFin);
  const toggleModalReprovado = () => setModalReprovado(!modalReprovado);
  const toggleModalAlterarPrazo = () => setModalAlterarPrazo(!modalAltPra);
  const toggleModalEncaminhar = () => setModalEncaminhar(!modalEncaminhar);
  const toggleModalFinalizarChamado = () => setModalFinalizarChamado(!modalFinalizarChamado);
  
  function openRename(idAnexo, textoAnexo, tipoAnexo){ 
    setInfoRenameFile({'id': idAnexo, 'name': textoAnexo, 'tipo': tipoAnexo})
    setRename(true)
  };

  const toggleRename = () => setRename(!modalRename)

  useEffect(() => {
      getChamado();
      loadDepartamentos();
  }, [])

  /////////// PARAMS COLLAPSE VIEWERS ///////////
  const [isOpenFin, setIsOpenFin] = useState(false);
  const toggleFin = () => setIsOpenFin(!isOpenFin);

  const [isOpenPed, setIsOpenPed] = useState(false);
  const togglePed = () => setIsOpenPed(!isOpenPed);

  const [isOpenCom, setIsOpenCom] = useState(false);
  const toggleCom = () => setIsOpenCom(!isOpenCom);

  const [isOpenCob, setIsOpenCob] = useState(false);
  const toggleCob = () => setIsOpenCob(!isOpenCob);

  const [isOpenAdm, setIsOpenAdm] = useState(false);
  const toggleAdm = () => setIsOpenAdm(!isOpenAdm);

  const [isOpenMrk, setIsOpenMrk] = useState(false);
  const toggleMrk = () => setIsOpenMrk(!isOpenMrk);

  const [isOpenSg, setIsOpenSg] = useState(false);
  const toggleSg = () => setIsOpenSg(!isOpenSg);

  const [isOpenBbk, setIsOpenBbk] = useState(false);
  const toggleBbk = () => setIsOpenBbk(!isOpenBbk);

  const [isOpenTI, setIsOpenTI] = useState(false);
  const toggleTI = () => setIsOpenTI(!isOpenTI);
  /////////// PARAMS COLLAPSE VIEWERS ///////////

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  }
  
  const toggleDrop = () => setDropdownOpen(prevState => !prevState);
  
  const getChamado = () => {
    localStorage.setItem('viewers', visualizadoresadd)
    const token = localStorage.getItem('token');
    const user_login = JSON.parse(localStorage.getItem('user_login'));
    const chamadoId = localStorage.getItem('chamadoId');

    api.post('/chamados-pwa/visualizar', {
        id: chamadoId,
        usuario_id: user_login.id,
    }, {
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
        }
    }).then(resposta => {
      console.log('++++++chamdo++++++', resposta.data)
        setChamado(resposta.data.chamado)
        setAnexos(resposta.data.anexos)
        setHistorico(resposta.data.historico)
        setObservacao(resposta.data.observacao)
        setVisualizadores(resposta.data.visualizadores)
    });

    api.post('/chamados-pwa/lista-visualizadorespwa', {
        id: chamadoId
    }, {
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
        }
    }).then(resposta => {
        setVisualizadoresadd(resposta.data)
    });
      
  }

  const getMessagesObs = (obsId) => {

    const chamadoId = localStorage.getItem('chamadoId');
    const token = localStorage.getItem('token');
    toggleModalChat()
    if (!modalChat) {
      setModalChat(true)
    }
    setObsId(obsId)

    api.post('/chamados-pwa/resposta', {
        chamado_id: chamadoId,
        id: obsId
    }, {
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
        }
    }).then(resposta => {

      if (resposta.data.length >= 1){
        const messages = resposta.data.map((message, i) => {
          let avatar = 'http://erp.ciebe.com.br' + message.usuario_foto;
          return  <div className="comentario">
                      <p style={{ fontSize: 8 }}> {message.created}</p>
                      <Media>
                        <Media left >
                          <img src={avatar} width="64" height="64" alt="" className="imgPerson" />
                        </Media>
                        <Media body className="textPerson">
                          <Media heading style={{ fontSize: 15 }}>
                            {message.usuario_nome}
                          </Media>
                          {message.texto}
                        </Media>
                      <hr></hr>
                      </Media>
                      <hr></hr>
                  </div>
        });
        setChatMessages(messages)
      }else{
        // toggleModalChat()
        setChatMessages([])
      }
 
      
    });

    
  }

  const removerVisualizador = (visualizador) => {

    const token = localStorage.getItem('token');
    const user_login = JSON.parse(localStorage.getItem('user_login'));
    const chamadoId = localStorage.getItem('chamadoId');

    let info = []
    info.title = 'Removendo...'
    info.textConfirm = 'Fechar'
    setinfoToastr(info)
    toggleToastr()

    api.post('/chamados-pwa/remover-visualizadores', {
        id: visualizador,
        chamado_id: chamadoId,
        usuario_id: user_login.id,
    }, {
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
        }
    }).then(resposta => {
      // const info = []
     
      // info.title = resposta.data
      // info.textConfirm = 'Fechar'
      // setinfoToastr(info)
      // toggleToastr()

      setTimeout(() => {
        setShowToastr(false)
        getChamado()        
      }, 1500);
      
    });
  }

  const renomearArquivo = (id) => {

    const token = localStorage.getItem('token');
    const user_login = JSON.parse(localStorage.getItem('user_login'));

    console.log('teste', newNameFile)

    let info = []
    info.title = 'Renomeando...'
    info.textConfirm = 'Fechar'
    setinfoToastr(info)
    toggleToastr()
    
    api.post('/chamados-pwa/renomeia-arquivo', {
      anexo_id: infoRenameFile.id,
      usuario_id: user_login.id, 
      nome: newNameFile+'.'+infoRenameFile.tipo
    }, { 
      headers: { 
        'Authorization': 'Bearer ' + token, 
        'charset': 'utf-8' 
      } 
    }).then(resposta => {
      setNewNameFile('')
      toggleRename()
      setShowToastr(false)
      setTimeout(() => {
        getChamado()        
      }, 1500);
      
    });
  }
  
  const listObs = observacao.map((obs, i) =>{
    let avatar = 'http://erp.ciebe.com.br' + obs.usuario_foto;

    let coment_or_coments = ''

    if(obs.total_resposta >= 1 ){
      coment_or_coments = 'S'
    }else{
      coment_or_coments = ''
    }

    let button_feito = ''

    if(obs.feito == true){
      button_feito = <Button color="danger" onClick={() => { obsFeito(obs.id, obs.feito) }} style={{ marginRight: 3, fontSize: 11, padding: 5 }}>
                            <Icon.ThumbsUp color="white" size={12} className="icon-navbar" /> MARCAR COMO NÃO FEITO
                          </Button>
    }else{
      button_feito = <Button color="primary" onClick={() => { obsFeito(obs.id, obs.feito) }} style={{ marginRight: 3, fontSize: 11, padding: 5 }}>
                            <Icon.ThumbsUp color="white" size={12} className="icon-navbar" /> MARCAR COMO FEITO
                          </Button>
    }

    return  <Col sm="12">
              <Card body style={{ marginBottom: 10 }}>
                <p style={{ fontSize: 9, color: 'grey', marginLeft: -10, marginTop: -10}}><Icon.Clock size={10} /> {obs.created}</p>
                <CardTitle>
                  <p>
                    <img src={avatar} className="image_avatar" width="52" height="52" style={{ marginRight: 10 }}/>
                    {obs.usuario_nome}
                  </p>
                </CardTitle>
                <CardText>{obs.texto}</CardText>
                <FormGroup>
                  {button_feito}
                  <Button color="info" style={{ marginRight: 3, fontSize: 11, padding: 5}} onClick={ () => { getMessagesObs(obs.id) }}>
                    <Icon.MessageCircle color="white" size={12}  className="icon-navbar" /> {obs.total_resposta} COMENTÁRIO{coment_or_coments} 
                  </Button>
                </FormGroup>
              </Card>
            </Col>
  });

  const visualizadoresColourStyles = {
    
    control: styles => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      const color = chroma( '#adabab');
      return {
        ...styles,
        backgroundColor: isDisabled
          ? null
          : isSelected
            ? data.color
            : isFocused
              ? color.alpha(0.1).css()
              : null,
        color: isDisabled
          ? '#ccc'
          : isSelected
            ? chroma.contrast(color, 'white') > 2
              ? 'white'
              : 'black'
            : data.color,
        cursor: isDisabled ? 'not-allowed' : 'default',

        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
        },
      };
    },
    multiValue: (styles, { data }) => {
      const color = chroma(data.color);
      return {
        ...styles,
        backgroundColor: color.alpha(0.1).css(),
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: data.color,
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: data.color,
      ':hover': {
        backgroundColor: data.color,
        color: 'white',
      },
    }),
  };
  
  const listVisualizadores = visualizadores.map((visualizador, i) => {
    let avatar = 'http://erp.ciebe.com.br' + visualizador.usuario_foto;
    let buttonRemove = ''
    if (visualizador.usuario_id == 42 || visualizador.usuario_id == 168 || visualizador.usuario_id == 15) {
      buttonRemove =  <Button color="danger" disabled onClick={ () => { removerVisualizador(visualizador.usuario_id)} } style={{ marginRight: 3, fontSize: 15, padding: 5}}>
                        <Icon.XCircle color="white" size={18}  className="icon-navbar" /> Remover
                      </Button>
    }else{
      buttonRemove =  <Button color="danger" onClick={ () => { removerVisualizador(visualizador.usuario_id)} } style={{ marginRight: 3, fontSize: 15, padding: 5}}>
                        <Icon.XCircle color="white" size={18}  className="icon-navbar" /> Remover
                      </Button>
    }

    return <Col sm="12">
            <Card body style={{ marginTop: 10 }}>
              <p style={{ fontSize: 9, color: 'grey', marginLeft: -10, marginTop: -10}}>{visualizador.criado}</p>
              <CardTitle>
                <p style={{ marginBottom: 0 }}>
                  <img src={avatar} className="image_avatar" width="52" height="52" style={{ marginRight: 10 }}/>
                  {visualizador.usuario_nome}
                </p>
                <Badge color="primary" pill>{visualizador.funcao}</Badge>
              </CardTitle>
                {buttonRemove}
            </Card>
          </Col>
  });

  // const getFiles = (file: FileList) => {
  //   setFiles(file)
  // }

  const uploadFiles = () => {
    const token = localStorage.getItem('token');
    const user_login = JSON.parse(localStorage.getItem('user_login'));
    const chamadoId = localStorage.getItem('chamadoId');
    console.log('files_get', files)

    api.post('/chamados-pwa/inserir-anexos', {
      myfile: files,
      id: chamadoId,
      usuario_id: user_login.id,
    }, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
        'charset': 'utf-8'
      }
    }).then(resposta => {
      getChamado()
      console.log('resposta do File', resposta)
      setFiles([])
      // setTimeout(() => {
      //   setShowToastr(false)
      //   getChamado()
      // }, 1500);

    });
    toggleFile()
  }

  const cancelFiles = () => {
    setFiles([])
    toggleFile()
  }

  const uploadImage = async file => {
    const formData = new FormData();
    formData.append('file', file);

    // Connect to a seaweedfs instance
  };

  function selHandleOnChange(e) {
    getViewerId(e.params.data.id)
    console.log('setSelectvisualizadores', selectvisualizadores)
  }

  const visualizadoresSelecionados = []
  function selDefaultValueVisualizadores() {
    visualizadores.map((vis, i)=> {
      visualizadoresSelecionados.push(vis.usuario_id)
    })
    
    return visualizadoresSelecionados
  }

  selDefaultValueVisualizadores()

  const select2Visualizadores = {}

  const listNovoVisualizadores = visualizadoresadd.map((vis, a) =>{
    
    const membros = vis.children.map((child, i) => {
      return select2Visualizadores[i] = [{ 'text': vis.name, 'children': [{ 'id': child.id, 'text': (<div> <Icon.Airplay className="icon-navbar" size={15} /> child.name </div>) }]}]
       
      // visualizadores.map((getVis, a) => {
      //   if (getVis.usuario_id == child.usuario_id == true) {
      //     child.usuario_id = false
      //   } else {
      //     child.usuario_id = true
      //   }
      
      // })
      
      // let checked = ''
      // if(child.checked){
      //   checked = <Icon.Check color="black" size={15}  className="icon-navbar" />
      // }else{
      //   checked = ''
      // }
      // // http://erp.ciebe.com.br/upload/funcionarios/1522247369_blob.blob
      // let avatar
      // if(child.foto == null){
      //   avatar = 'http://erp.ciebe.com.br/upload/funcionarios/empty.png'
      // }else{
      //   avatar = 'http://erp.ciebe.com.br' + child.foto
      // }
      // return <Media id={child.id} onClick={() => { getViewerId(child.id); setViewerChecked(child) }} >
      //   <Media left >
      //     <img src={avatar} width="64" height="64" alt="" className="imgPerson" />
      //   </Media>
      //   <Media body className="textPerson">
      //     <Media heading style={{ fontSize: 15 }}>
      //       {checked} {child.name}
      //     </Media>
      //   </Media>
      //   <hr></hr>
      // </Media>


        
    });


   
  // return  <Col sm="6">
  //           {vis.name == 'Comercial' && 
  //             <div>
  //               <Card body onClick={toggleCom} style={{ marginTop: 10,  marginBottom: 15 }} id={vis.id}>
  //                 <CardText>
  //                     <strong>{vis.name}</strong>
  //                 </CardText> 
  //               </Card>
  //               <Collapse isOpen={isOpenCom}>
  //                 <Container>
  //                   {membros}
  //                 </Container>                  
  //               </Collapse>
  //             </div>
  //           }
  //           {vis.name == 'Administrativo' && 
  //             <div>
  //               <Card body  onClick={toggleAdm} style={{ marginTop: 10,  marginBottom: 15 }} id={vis.id}>
  //                 <CardText>
  //                     <strong>{vis.name}</strong>
  //                 </CardText>
  //               </Card>
  //               <Collapse isOpen={isOpenAdm}>
  //                 <Container>
  //                   {membros}
  //                 </Container>
  //               </Collapse>
  //             </div>
  //           }
  //           {vis.name == 'Biblioteca' && 
  //             <div>
  //               <Card body  onClick={toggleBbk} style={{ marginTop: 10,  marginBottom: 15 }} id={vis.id}>
  //                 <CardText>
  //                     <strong>{vis.name}</strong>  
  //                 </CardText>
  //               </Card>
  //               <Collapse isOpen={isOpenBbk}>
  //                 <Container>
  //                   {membros}
  //                 </Container>
  //               </Collapse>
  //             </div>
  //           }
  //           {vis.name == 'Financeiro' && 
  //             <div>
  //               <Card body  onClick={toggleFin} style={{ marginTop: 10,  marginBottom: 15 }} id={vis.id}>
  //                 <CardText>
  //                     <strong>{vis.name}</strong>   
  //                 </CardText>
  //               </Card>
  //               <Collapse isOpen={isOpenFin}>
  //                 <Container>
  //                   {membros}
  //                 </Container>
  //               </Collapse>
  //             </div>
  //           }
  //           {vis.name == 'Serviços Gerais' && 
  //             <div>
  //               <Card body  onClick={toggleSg} style={{ marginTop: 10,  marginBottom: 15 }} id={vis.id}>
  //                 <CardText>
  //                     <strong>{vis.name}</strong>   
  //                 </CardText>
  //               </Card>
  //               <Collapse isOpen={isOpenSg}>
  //                 <Container>
  //                   {membros}
  //                 </Container>
  //               </Collapse>
  //             </div>
  //           }
  //           {vis.name == 'Marketing' && 
  //             <div>
  //               <Card body  onClick={toggleMrk} style={{ marginTop: 10,  marginBottom: 15 }} id={vis.id}>
  //                 <CardText>
  //                     <strong>{vis.name}</strong>   
  //                 </CardText>
  //               </Card>
  //               <Collapse isOpen={isOpenMrk}>
  //                 <Container>
  //                   {membros}
  //                 </Container>
  //               </Collapse>
  //             </div>
  //           }
  //           {vis.name == 'Pedagógico' && 
  //             <div>
  //               <Card body  onClick={togglePed} style={{ marginTop: 10,  marginBottom: 15 }} id={vis.id}>
  //                 <CardText>
  //                     <strong>{vis.name}</strong>   
  //                 </CardText>
  //               </Card>
  //               <Collapse isOpen={isOpenPed}>
  //                 <Container>
  //                   {membros}
  //                 </Container>
  //               </Collapse>
  //             </div>
  //           }
  //           {vis.name == 'Cobrança.' && 
  //             <div>
  //               <Card body  onClick={toggleCob} style={{ marginTop: 10,  marginBottom: 15 }} id={vis.id}>
  //                 <CardText>
  //                     <strong>{vis.name}</strong>   
  //                 </CardText>
  //               </Card>
  //                 <Collapse isOpen={isOpenCob}>
  //                   <Container>
  //                     {membros}
  //                   </Container>
  //                 </Collapse>
  //             </div>
  //           }
  //           {vis.name == 'T.I.' && 
  //             <div>
  //               <Card body  onClick={toggleTI} style={{ marginTop: 10,  marginBottom: 15 }} id={vis.id}>
  //                 <CardText>
  //                     <strong>{vis.name}</strong>   
  //                 </CardText>
  //               </Card>
  //               <Collapse isOpen={isOpenTI}>
  //               <Container>
  //                 {membros}
  //               </Container>
  //               </Collapse>
  //             </div>
  //           }
  //         </Col>
  });

  const listHistorico = historico.map((hist, i) =>{
  return  <Col sm="12">
            <Card body style={{ marginTop: 10 }}>
              <CardText>
                  {hist}
              </CardText>
            </Card>
          </Col>
  });
    
  const listAnexos = anexos.map((anexo, i) =>{
    const user_login = JSON.parse(localStorage.getItem('user_login'))
    let avatar = 'http://erp.ciebe.com.br' + anexo.usuario_foto;
    let anexo_icone = <Icon.File color="black" size={25}  className="icon-navbar" />
    let button_rename = ''

    if(anexo.tipo == 'png' || anexo.tipo == 'jpg' || anexo.tipo == 'jpeg'){
      anexo_icone = <Icon.Image color="black" size={25}  className="icon-navbar" />
    }

    if(anexo.tipo == 'pdf'){
      anexo_icone = <Icon.FileText color="black" size={25}  className="icon-navbar" />
    }

    if(anexo.usuario_id == user_login.id){
      button_rename = <div>
                        <a href={anexo.url} target="_blank">
                          <Button color="primary" style={{ marginRight: 3, fontSize: 12, padding: 5 }}>
                            <Icon.Download color="white" size={12} className="icon-navbar" /> Download
                                  </Button>
                        </a>
                        <Button onClick={ () => openRename(anexo.id, anexo.nome, anexo.tipo)} color="primary" style={{ marginRight: 3, fontSize: 12, padding: 5 }}>
                          <Icon.Edit3 color="white" size={12} className="icon-navbar" /> Renomear
                        </Button>
                      
                      </div>
    }else{
      button_rename = <div>
                        <a href={anexo.url} target="_blank">
                          <Button color="primary" style={{ marginRight: 3, fontSize: 12, padding: 5 }}>
                            <Icon.Download color="white" size={12} className="icon-navbar" /> Download
                          </Button>
                        </a>
                      </div>
    }

    return  <Col sm="6">
              <Card body style={{ marginTop: 10 }}>
                <p style={{ fontSize: 9, color: 'grey', marginLeft: -10, marginTop: -10}}>{anexo.criado}</p>
                <CardTitle>
                  <p>
                    {anexo_icone} {anexo.nome}
                  </p>
                </CardTitle>
                <CardBody>
                  {button_rename}
                </CardBody>
              </Card>
            </Col>
  });

  const removeFile = (filePosition) => {
    console.log('files', files)
    files.splice(filePosition, 1);
    // console.log('newFiles', newFiles)
    setFiles([])

    setTimeout(() => {
      setFiles(files)
    }, 50);

    // api.post('/chamados-pwa/remover-anexo', {
    //   myfile: files,
    //   id: chamadoId,
    //   usuario_id: user_login.id,
    // }, {
    //   headers: {
    //     Accept: 'application/json',
    //     Authorization: `Bearer ${token}`,
    //     'charset': 'utf-8'
    //   }
    // }).then(resposta => {
    //   console.log('resposta do File', resposta)
    //   // setTimeout(() => {
    //   //   setShowToastr(false)
    //   //   getChamado()
    //   // }, 1500);

    // });

    console.log('After remove', files)
  }

  const loadDepartamentos = () => {

    const token = localStorage.getItem('token');
    const user_login = JSON.parse(localStorage.getItem('user_login'));

    // console.log('user_login', user_login.id)

    api.post('/chamados-pwa/pega-departamento', {
      id: user_login.id,
    }, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
      }
    }).then(resposta => {
      console.log(resposta.data)
      setoptionsDepartamento(resposta.data)
    });
  }

  const optionsDepartamentos = optionsDepartamento.map((option, i) => {
    return <option value={option.id} key={option.id}>{option.nome}</option>
  });

  const loadAtendentes = (id) => {

    const token = localStorage.getItem('token');
    const user_login = JSON.parse(localStorage.getItem('user_login'));

    api.post('/chamados-pwa/pega-atendente', {
      id: id,
    }, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
      }
    }).then(resposta => {
      setselectedDepartamento(id)
      console.log(resposta.data)
      setoptionsAtendente(resposta.data)
    });
  }

  const selectAtendente = (id) => {
    setselectedAtendente(id)
  }

  const optionsAtendentes = optionsAtendente.map((option, i) => {
    return <option value={option.id} key={option.id}>{option.nome}</option>
  });

  let listFiles = files.map((file, i) =>{
   
    let anexo_icone = <Icon.File color="black" size={25}  className="icon-navbar" />

    if(file.type == 'application/png' || file.type == 'application/jpg' || file.type == 'application/jpeg'){
      anexo_icone = <Icon.Image color="black" size={25}  className="icon-navbar" />
    }

    if(file.type == 'application/pdf'|| file.type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"){
      anexo_icone = <Icon.FileText color="black" size={25}  className="icon-navbar" />
    }

    let extension = file.type.split('/')
    if (extension[0] == "video") {
      alert("Arquivos de video não são permitidos!")
      setTimeout(() => {
        setFiles([])
      }, 50);
    }

    return  <Col sm="6">
              <Card body style={{ marginTop: 10 }}>
                <CardTitle>
                  <p>
                    {anexo_icone} {file.name}
                  </p>
                </CardTitle>
                
                {/* <Button color="danger" onClick={ () => { removeFile(i) }} style={{ marginRight: 3, fontSize: 15, padding: 5}}>
                  <Icon.Trash color="white" size={20}  className="icon-navbar" /> Remover
                </Button> */}
                
              </Card>
            </Col>
  });

  function obsFeito(obs_id, obs_status){

    const token = localStorage.getItem('token');
    const user_login = JSON.parse(localStorage.getItem('user_login'));
    const chamadoId = localStorage.getItem('chamadoId');

    let info = []
    info.title = 'Atualizando...'
    info.textConfirm = 'Fechar'
    setinfoToastr(info)
    toggleToastr()
    
    api.post('/chamados-pwa/feito', {
      id: obs_id,
      usuario_id: user_login.id
    }, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
      }
    }).then(resposta => {
      setShowToastr(false)
      getChamado()
    });
    
  }
  
  function enviarObs(){
    const token = localStorage.getItem('token');
    const user_login = JSON.parse(localStorage.getItem('user_login'));
    const chamadoId = localStorage.getItem('chamadoId');
    const info = []

    info.title = 'Comentário'
    info.content = 'Enviando...'
    info.textConfirm = 'Aguarde'
    setinfoToastr(info)
    toggleToastr()

    api.post('/chamados-pwa/adiciona-observacao', {
      usuario_id: user_login.id,
      id: chamadoId,
      texto: textoObservacao
    }, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
      }
    }).then(resposta => {
      toggleModalObs()
      setShowToastr(false)      
      getChamado()
      settextoObservacao('')
    });
  }

  function chamadoAprovado() {
    const token = localStorage.getItem('token');
    const user_login = JSON.parse(localStorage.getItem('user_login'));
    const chamadoId = localStorage.getItem('chamadoId');
    const info = []

    info.title = 'Chamado Aprovado'
    info.content = 'Enviando...'
    info.textConfirm = 'Aguarde'
    setinfoToastr(info)
    toggleToastr()

    api.post('/chamados-pwa/aprovar', {
      usuario_id: user_login.id,
      id: chamadoId,
    }, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
      }
    }).then(resposta => {
      setShowToastr(false)
      getChamado()
    });
  }

  function chamadoReprovado() {
    const token = localStorage.getItem('token');
    const user_login = JSON.parse(localStorage.getItem('user_login'));
    const chamadoId = localStorage.getItem('chamadoId');
    const info = []

    console.log('Txt Reprovado', textoReprovado)

    info.title = 'Chamado Reprovado'
    info.content = 'Enviando...'
    info.textConfirm = 'Aguarde'
    setinfoToastr(info)
    toggleToastr()

    api.post('/chamados-pwa/reprovar', {
      usuario_id: user_login.id,
      id: chamadoId,
      texto: textoReprovado
    }, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
      }
    }).then(resposta => {
      setShowToastr(false)      
      getChamado()
      redirectToastr()
    });
  }

  function chamadoFinalizar() {

    const token = localStorage.getItem('token');
    const user_login = JSON.parse(localStorage.getItem('user_login'));
    const chamadoId = localStorage.getItem('chamadoId');
    
    let info = []
    info.title = 'Finalizando Chamado...'
    info.textConfirm = 'Fechar'
    setinfoToastr(info)
    toggleToastr()

    api.post('/chamados-pwa/finalizar', {
      id: chamadoId,
      usuario_id: user_login.id,
      texto: textoFinalizarChamado
    }, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
      }
    }).then(resposta => {
      setShowToastr(false)
      getChamado()
      redirectToastr()
    });

  }

  function alterarPrazo() {

    const token = localStorage.getItem('token');
    const user_login = JSON.parse(localStorage.getItem('user_login'));
    const chamadoId = localStorage.getItem('chamadoId');

    console.log('prazoChamado', prazoChamado)

    let info = []
    info.title = 'Alterando Prazo...'
    info.textConfirm = 'Fechar'
    setinfoToastr(info)
    toggleToastr()

    api.post('/chamados-pwa/alterar-prazo', {
      id: chamadoId,
      usuario_id: user_login.id,
      prazo: prazoChamado
    }, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
      }
    }).then(resposta => {
      setShowToastr(false)
      getChamado()
      redirectToastr()
    });

  }
  
  function enviarMsg() {
    const token = localStorage.getItem('token');
    const user_login = JSON.parse(localStorage.getItem('user_login'));
    const chamadoId = localStorage.getItem('chamadoId');
    const info = []

    info.title = 'Resposta'
    info.content = 'Atualizando...'
    info.textConfirm = 'Aguarde'
    setinfoToastr(info)
    toggleToastr()

    api.post('/chamados-pwa/adiciona-resposta', {
      usuario_id: user_login.id,
      observacao_id: obdsId,
      id: chamadoId,
      texto: textoChat
    }, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
      }
    }).then(resposta => {
      settextoChat('')
      getChamado()
      setShowToastr(false)
      getMessagesObs()
    });
  }

  function sendFinalizarChamado(){
    const token = localStorage.getItem('token');
    const user_login = JSON.parse(localStorage.getItem('user_login'));
    const chamadoId = localStorage.getItem('chamadoId');
    const info = []
   
    api.post('/chamados-pwa/finalizar', {
        usuario_id: user_login.id,
        id: chamadoId,
        texto: textoFinalizar
    }, {
      headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${token}`
        }
    }).then(resposta => {
          toggleModalFinalizar()
          toggleModalDesc()

          info.title = 'Chamado #'+chamadoId
          // info.content = resposta.data
          info.content = 'finalizado'
          info.textConfirm = 'Redirecionando...'
          setinfoToastr(info)
          toggleToastr()
          redirectToastr()
         
      });
  }

  function redirectToastr(){
    setTimeout(() => {
      window.location.href = '/lista_chamado'
    }, 2500);
  }



  function getViewerId(viewId) {    
    let viewers = selectvisualizadores
    viewers.push(viewId)
    setSelectvisualizadores(viewers)
    // localStorage.setItem('viewers', viewers.push([viewId]))
  }


  // function setViewerChecked(el) {
  //   if(el.checked == true){
  //     el.checked = false
  //   }else{
  //     el.checked = true
  //   }
  //   setIsOpenFin(!isOpenFin);
  //   setIsOpenPed(!isOpenPed);
  //   setIsOpenCom(!isOpenCom);
  //   setIsOpenCob(!isOpenCob);
  //   setIsOpenAdm(!isOpenAdm);
  //   setIsOpenMrk(!isOpenMrk);
  //   setIsOpenSg(!isOpenSg);
  //   setIsOpenBbk(!isOpenBbk);
  //   setIsOpenTI(!isOpenTI);
  //   setTimeout(() => {
  //     setIsOpenFin(isOpenFin);
  //     setIsOpenPed(isOpenPed);
  //     setIsOpenCom(isOpenCom);
  //     setIsOpenCob(isOpenCob);
  //     setIsOpenAdm(isOpenAdm);
  //     setIsOpenMrk(isOpenMrk);
  //     setIsOpenSg(isOpenSg);
  //     setIsOpenBbk(isOpenBbk);
  //     setIsOpenTI(isOpenTI);
  //   }, 1);
  // }

  function sendViewers() {
    // let newViewers = localStorage.getItem('viewers')
    const token = localStorage.getItem('token');
    const user_login = JSON.parse(localStorage.getItem('user_login'));
    const chamadoId = localStorage.getItem('chamadoId');

    const info = []
    info.title = 'Aguarde...'
    info.textConfirm = 'Fechar'
    setinfoToastr(info)
    toggleToastr()

    console.log('selectvisualizadores', selectvisualizadores)

    api.post('/chamados-pwa/adiciona-visualizadores', {
      ids: selectvisualizadores,
      chamado_id: chamadoId,
      usuario_id: user_login.id
    }, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
      }
    }).then(resposta => {
      toggleModalVis()
      setShowToastr(false)
      getChamado();
    });
  }

  function encaminharChamado() {
    const token = localStorage.getItem('token');
    const user_login = JSON.parse(localStorage.getItem('user_login'));
    const chamadoId = localStorage.getItem('chamadoId');

    const form = new FormData()

    files.map((file, i) => {
      form.append(i, {
        name: file.name,
        data: file.base64,
        type: file.type,
        size: file.size
      })
    });
    form.append('Content-Type', 'multipart/form-data');

    const info = []
    info.title = 'Encaminhando...'
    info.textConfirm = 'Fechar'
    setinfoToastr(info)
    toggleToastr()
    let prazoFormat = prazoEncaminhar + ' 18:00'
    console.log('store', prazoFormat)

    api.post('/chamados-pwa/encaminhar', {
      chamado_id: chamadoId,
      departamento: selectedDepartamento,
      atendente_id: selectedAtendente,
      prazo: prazoFormat,
      observacoes: encaminharDescricao,
      arquivos: form,
      usuario_id: user_login.id
    }, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
        'charset': 'utf-8'
      }
    }).then(resposta => {
      console.log('Encaminhar', resposta)
      // toggleModalEncaminhar()
      setModalEncaminhar(false)
      setShowToastr(false)
      getChamado();
      redirectToastr()
    });
  }

  function removerChamado() {
    const token = localStorage.getItem('token');
    const user_login = JSON.parse(localStorage.getItem('user_login'));
    const chamadoId = localStorage.getItem('chamadoId');

   
    const info = []
    info.title = 'Excluindo...'
    info.textConfirm = 'Aguarde'
    setinfoToastr(info)
    toggleToastr()
    let prazoFormat = prazoEncaminhar + ' 18:00'

    api.post('/chamados-pwa/remover', {
      id: chamadoId,
      usuario_id: user_login.id
    }, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
        'charset': 'utf-8'
      }
    }).then(resposta => {
      console.log('Excluir', resposta)
      setModalEncaminhar(false)
      setShowToastr(false)
      getChamado();
      redirectToastr()
    });
  }

  const user_login = JSON.parse(localStorage.getItem('user_login'));
  const describe = <Card style={{ border: '0px' }} ><div dangerouslySetInnerHTML={{ __html: chamado.descricao }} /></Card>;
  let aprovedButtons;
  let status = [];
  let buttonsModalDesc;

  if (chamado.status == 1) {
    status.badge = 'primary';
    status.text = 'Aguardando Atendimento';
    aprovedButtons = '';
  }
  
  if (chamado.status == 3) {
    status.badge = 'success';
    status.text = 'Em Atendimento';
    aprovedButtons = '';

    
    
    if (user_login.super_usuario == 1){
      buttonsModalDesc =  <div>
                            <ListGroup>
                              <ListGroupItem tag="a" onClick={toggleModalFinalizarChamado} style={{ color: '#559CDF' }}>
                                Finalizar
                              </ListGroupItem>
                              <ListGroupItem tag="g" onClick={toggleModalAlterarPrazo} style={{ color: '#559CDF' }}>
                                Alterar Prazo
                              </ListGroupItem>
                              <ListGroupItem tag="c" onClick={toggleModalEncaminhar} style={{ color: '#559CDF' }}>
                                Encaminhar
                              </ListGroupItem>
                              <ListGroupItem tag="d" onClick={removerChamado} style={{ color: '#559CDF' }}>
                                Excluir
                              </ListGroupItem>
                            </ListGroup>
                            {/* Modal Finalizar*/}
                              <Modal isOpen={modalFinalizarChamado} toggle={toggleModalFinalizarChamado}>
                                <ModalBody>
                                  <Label>Finalizar chamado </Label>
                                  <Input type="textarea" name="new_obs" id="new_obs" rows={6}  onChange={ e => settextoFinalizarChamado(e.target.value) } value={textoFinalizarChamado}  />
                                </ModalBody>
                                <ModalFooter>
                                  <Button color="info" size="lg" onClick={toggleModalFinalizarChamado}><Icon.X color="white" size={15} className="icon-navbar" /> Cancelar</Button>
                                  <Button color="primary" size="lg" onClick={() => chamadoFinalizar()}><Icon.Save color="white" size={15} className="icon-navbar" /> Salvar</Button>
                                </ModalFooter>
                              </Modal>
                            {/* /Modal Finalizar*/}
                            {/* Modal Alterar Prazo*/}
                              <Modal isOpen={modalAltPra} toggle={toggleModalAlterarPrazo}>
                                <ModalBody>
                                  <Label>Alterar Prazo </Label>
                                  <Input
                                    type="date"
                                    name="date"
                                    id="prazo"
                                    placeholder="XX/XX/XXXX"
                                    onChange={ e => setprazoChamado(e.target.value) } value={prazoChamado} 
                                  />
                                </ModalBody>
                                <ModalFooter>
                                  <Button color="info" size="lg" onClick={toggleModalAlterarPrazo}><Icon.X color="white" size={15} className="icon-navbar" /> Cancelar</Button>
                                  <Button color="primary" size="lg" onClick={() => alterarPrazo()}><Icon.Save color="white" size={15} className="icon-navbar" /> Salvar</Button>
                                </ModalFooter>
                              </Modal>
                            {/* /Modal Alterar Prazo*/}
                            {/* Modal Encaminhar*/}
                              <Modal isOpen={modalEncaminhar} toggle={toggleModalEncaminhar} >
                                <ModalBody>
                                  <FormGroup>
                                    <Label for="departamento">Departamento</Label>
                                    <Input type="select" name="select" id="departamento" onChange={ (e) => { loadAtendentes(e.target.value) } }>
                                      <option selected disabled>Selecione</option>
                                      {optionsDepartamentos}
                                    </Input>
                                  </FormGroup>
                                  <FormGroup>
                                    <Label for="atendente">Atendente</Label>
                                    <Input type="select" name="select" id="atendente" onChange={(e) => { selectAtendente(e.target.value) }}>
                                      <option selected disabled>Selecione</option>
                                      {optionsAtendentes}
                                    </Input>
                                  </FormGroup>
                                  <FormGroup>
                                    <Label for="exampleDate">Prazo</Label>
                                    <Input
                                      type="date"
                                      name="date"
                                      id="prazoEncaminhar"
                                      placeholder="XX/XX/XXXX"
                                      onChange={ e => setPrazoEncaminhar(e.target.value) } value={prazoEncaminhar} 
                                    />
                                  </FormGroup>
                                  <FormGroup>
                                    <Label>Descrição</Label>
                                    <Input type="textarea" name="encaminharDescricao" id="encaminharDescricao" placeholder="Aa..." rows="4" onChange={ e => setencaminharDescricao(e.target.value) } value={encaminharDescricao} />
                                  </FormGroup>
                                  <FormGroup>
                                    <Button colo="primary" size="lg" block onClick={toggleFile}>
                                      <Icon.Image color="white" size={25} className="icon-navbar" /> Anexar Imagens
                                    </Button>
                                  </FormGroup>
                                  
                                  {/* <Row>
                                    { listAnexos }
                                  </Row> */}
                                  <Row>
                                    { listFiles }
                                  </Row>

                                  {/* Anexar Arquivos */}
                                  <Modal isOpen={modalFile} toggle={toggleFile} backdrop="static">
                                    <ModalHeader toggle={toggleFile}>Anexar Arquivos</ModalHeader>
                                    <ModalBody>
                                      <Label for="anexarArquivo">PNG, JPG, JPEG, PDF, DOC...</Label>
                                      <FileBase64
                                        multiple={ true }
                                        onDone={ (e) => setFiles(e) } />
                                        {/* <hr></hr> */}
                                        {/* <Button color="danger" size="lg" block onClick={toggleCamera}>
                                          <Icon.Camera color="white" size={20} className="icon-navbar" /> Tirar foto
                                        </Button> */}
                                    </ModalBody>
                                    <ModalFooter>
                                      <Button color="secondary" onClick={() => { cancelFiles() }}>Cancelar</Button>
                                      <Button color="primary" onClick={() => { uploadFiles() }}>Pronto</Button>
                                    </ModalFooter>
                                  </Modal>
                                  {/* /Anexar Arquivos */}
                                </ModalBody>
                                <ModalFooter>
                                  <Button color="info" size="lg" onClick={toggleModalEncaminhar}><Icon.X color="white" size={15} className="icon-navbar" /> Cancelar</Button>
                                  <Button color="primary" size="lg" onClick={() => encaminharChamado()}><Icon.Save color="white" size={15} className="icon-navbar" /> Salvar</Button>
                                </ModalFooter>
                              </Modal>
                            {/* /Modal Encaminhar*/}
                            
                          </div>

    } else if (user_login.id != chamado.usuario_id){
      buttonsModalDesc =  <div>
                            <ListGroup>
                              <ListGroupItem tag="a" onClick={toggleModalFinalizarChamado} style={{ color: '#559CDF' }}>
                                Finalizar
                              </ListGroupItem>
                              <ListGroupItem tag="g" onClick={toggleModalAlterarPrazo} style={{ color: '#559CDF' }}>
                                Alterar Prazo
                              </ListGroupItem>
                              <ListGroupItem tag="c" onClick={toggleModalEncaminhar} style={{ color: '#559CDF' }}>
                                Encaminhar
                              </ListGroupItem>
                            </ListGroup>

                            {/* Modal Finalizar*/}
                              <Modal isOpen={modalFinalizarChamado} toggle={toggleModalFinalizarChamado}>
                                <ModalBody>
                                  <Label>Finalizar chamado </Label>
                                  <Input type="textarea" name="new_obs" id="new_obs" rows={6}  onChange={ e => settextoFinalizarChamado(e.target.value) } value={textoFinalizarChamado}  />
                                </ModalBody>
                                <ModalFooter>
                                  <Button color="info" size="lg" onClick={toggleModalFinalizarChamado}><Icon.X color="white" size={15} className="icon-navbar" /> Cancelar</Button>
                                  <Button color="primary" size="lg" onClick={() => chamadoFinalizar()}><Icon.Save color="white" size={15} className="icon-navbar" /> Salvar</Button>
                                </ModalFooter>
                              </Modal>
                            {/* /Modal Finalizar*/}

                            {/* Modal Alterar Prazo*/}
                              <Modal isOpen={modalAltPra} toggle={toggleModalAlterarPrazo}>
                                <ModalBody>
                                  <Label>Alterar Prazo </Label>
                                  <Input
                                    type="date"
                                    name="date"
                                    id="prazo"
                                    placeholder="XX/XX/XXXX"
                                    onChange={ e => setprazoChamado(e.target.value) } value={prazoChamado} 
                                  />
                                </ModalBody>
                                <ModalFooter>
                                  <Button color="info" size="lg" onClick={toggleModalAlterarPrazo}><Icon.X color="white" size={15} className="icon-navbar" /> Cancelar</Button>
                                  <Button color="primary" size="lg" onClick={() => alterarPrazo()}><Icon.Save color="white" size={15} className="icon-navbar" /> Salvar</Button>
                                </ModalFooter>
                              </Modal>
                            {/* /Modal Alterar Prazo*/}

                            {/* Modal Encaminhar*/}
                              <Modal isOpen={modalEncaminhar} toggle={toggleModalEncaminhar} >
                                <ModalBody>
                                  <FormGroup>
                                    <Label for="departamento">Departamento</Label>
                                    <Input type="select" name="select" id="departamento" onChange={ (e) => { loadAtendentes(e.target.value) } }>
                                      <option selected disabled>Selecione</option>
                                      {optionsDepartamentos}
                                    </Input>
                                  </FormGroup>
                                  <FormGroup>
                                    <Label for="atendente">Atendente</Label>
                                    <Input type="select" name="select" id="atendente" onChange={(e) => { selectAtendente(e.target.value) }}>
                                      <option selected disabled>Selecione</option>
                                      {optionsAtendentes}
                                    </Input>
                                  </FormGroup>
                                  <FormGroup>
                                    <Label for="exampleDate">Prazo</Label>
                                    <Input
                                      type="date"
                                      name="date"
                                      id="prazoEncaminhar"
                                      placeholder="XX/XX/XXXX"
                                      onChange={ e => setPrazoEncaminhar(e.target.value) } value={prazoEncaminhar} 
                                    />
                                  </FormGroup>
                                  <FormGroup>
                                    <Label>Descrição</Label>
                                    <Input type="textarea" name="encaminharDescricao" id="encaminharDescricao" placeholder="Aa..." rows="4" onChange={ e => setencaminharDescricao(e.target.value) } value={encaminharDescricao} />
                                  </FormGroup>
                                  <FormGroup>
                                    <Button colo="primary" size="lg" block onClick={toggleFile}>
                                      <Icon.Image color="white" size={25} className="icon-navbar" /> Anexar Imagens
                                    </Button>
                                  </FormGroup>
                                  
                                  {/* <Row>
                                    { listAnexos }
                                  </Row> */}
                                  <Row>
                                    { listFiles }
                                  </Row>

                                  {/* Anexar Arquivos */}
                                  <Modal isOpen={modalFile} toggle={toggleFile} backdrop="static">
                                    <ModalHeader toggle={toggleFile}>Anexar Arquivos</ModalHeader>
                                    <ModalBody>
                                      <Label for="anexarArquivo">PNG, JPG, JPEG, PDF, DOC...</Label>
                                      <FileBase64
                                        multiple={ true }
                                        onDone={ (e) => setFiles(e) } />
                                        {/* <hr></hr> */}
                                        {/* <Button color="danger" size="lg" block onClick={toggleCamera}>
                                          <Icon.Camera color="white" size={20} className="icon-navbar" /> Tirar foto
                                        </Button> */}
                                    </ModalBody>
                                    <ModalFooter>
                                      <Button color="secondary" onClick={() => { cancelFiles() }}>Cancelar</Button>
                                      <Button color="primary" onClick={() => { uploadFiles() }}>Pronto</Button>
                                    </ModalFooter>
                                  </Modal>
                                  {/* /Anexar Arquivos */}
                                </ModalBody>
                                <ModalFooter>
                                  <Button color="info" size="lg" onClick={toggleModalEncaminhar}><Icon.X color="white" size={15} className="icon-navbar" /> Cancelar</Button>
                                  <Button color="primary" size="lg" onClick={() => encaminharChamado()}><Icon.Save color="white" size={15} className="icon-navbar" /> Salvar</Button>
                                </ModalFooter>
                              </Modal>
                            {/* /Modal Encaminhar*/}
                          </div>

    } else if (user_login.id == chamado.usuario_id){
       buttonsModalDesc = <div>
                            <ListGroup>
                              <ListGroupItem tag="a" onClick={toggleModalFinalizarChamado} style={{ color: '#559CDF' }}>
                                Finalizar
                              </ListGroupItem>
                              <ListGroupItem tag="g" onClick={toggleModalAlterarPrazo} style={{ color: '#559CDF' }}>
                                Alterar Prazo
                              </ListGroupItem>
                              <ListGroupItem tag="c" onClick={toggleModalEncaminhar} style={{ color: '#559CDF' }}>
                                Encaminhar
                              </ListGroupItem>
                              
                            </ListGroup>

                            {/* Modal Alterar Prazo*/}
                              <Modal isOpen={modalAltPra} toggle={toggleModalAlterarPrazo}>
                                <ModalBody>
                                  <Label>Alterar Prazo </Label>
                                  <Input
                                    type="date"
                                    name="date"
                                    id="prazo"
                                    placeholder="XX/XX/XXXX"
                                    onChange={ e => setprazoChamado(e.target.value) } value={prazoChamado} 
                                  />
                                </ModalBody>
                                <ModalFooter>
                                  <Button color="info" size="lg" onClick={toggleModalAlterarPrazo}><Icon.X color="white" size={15} className="icon-navbar" /> Cancelar</Button>
                                  <Button color="primary" size="lg" onClick={() => alterarPrazo()}><Icon.Save color="white" size={15} className="icon-navbar" /> Salvar</Button>
                                </ModalFooter>
                              </Modal>
                            {/* /Modal Alterar Prazo*/}

                            {/* Modal Encaminhar*/}
                              <Modal isOpen={modalEncaminhar} toggle={toggleModalEncaminhar} >
                                <ModalBody>
                                  <FormGroup>
                                    <Label for="departamento">Departamento</Label>
                                    <Input type="select" name="select" id="departamento" onChange={ (e) => { loadAtendentes(e.target.value) } }>
                                      <option selected disabled>Selecione</option>
                                      {optionsDepartamentos}
                                    </Input>
                                  </FormGroup>
                                  <FormGroup>
                                    <Label for="atendente">Atendente</Label>
                                    <Input type="select" name="select" id="atendente" onChange={(e) => { selectAtendente(e.target.value) }}>
                                      <option selected disabled>Selecione</option>
                                      {optionsAtendentes}
                                    </Input>
                                  </FormGroup>
                                  <FormGroup>
                                    <Label for="exampleDate">Prazo</Label>
                                    <Input
                                      type="date"
                                      name="date"
                                      id="prazoEncaminhar"
                                      placeholder="XX/XX/XXXX"
                                      onChange={ e => setPrazoEncaminhar(e.target.value) } value={prazoEncaminhar} 
                                    />
                                  </FormGroup>
                                  <FormGroup>
                                    <Label>Descrição</Label>
                                    <Input type="textarea" name="encaminharDescricao" id="encaminharDescricao" placeholder="Aa..." rows="4" onChange={ e => setencaminharDescricao(e.target.value) } value={encaminharDescricao} />
                                  </FormGroup>
                                  <FormGroup>
                                    <Button colo="primary" size="lg" block onClick={toggleFile}>
                                      <Icon.Image color="white" size={25} className="icon-navbar" /> Anexar Imagens
                                    </Button>
                                  </FormGroup>
                                  
                                  {/* <Row>
                                    { listAnexos }
                                  </Row> */}
                                  <Row>
                                    { listFiles }
                                  </Row>

                                  {/* Anexar Arquivos */}
                                  <Modal isOpen={modalFile} toggle={toggleFile} backdrop="static">
                                    <ModalHeader toggle={toggleFile}>Anexar Arquivos</ModalHeader>
                                    <ModalBody>
                                      <Label for="anexarArquivo">PNG, JPG, JPEG, PDF, DOC...</Label>
                                      <FileBase64
                                        multiple={ true }
                                        onDone={ (e) => setFiles(e) } />
                                        {/* <hr></hr> */}
                                        {/* <Button color="danger" size="lg" block onClick={toggleCamera}>
                                          <Icon.Camera color="white" size={20} className="icon-navbar" /> Tirar foto
                                        </Button> */}
                                    </ModalBody>
                                    <ModalFooter>
                                      <Button color="secondary" onClick={() => { cancelFiles() }}>Cancelar</Button>
                                      <Button color="primary" onClick={() => { uploadFiles() }}>Pronto</Button>
                                    </ModalFooter>
                                  </Modal>
                                  {/* /Anexar Arquivos */}
                                </ModalBody>
                                <ModalFooter>
                                  <Button color="info" size="lg" onClick={toggleModalEncaminhar}><Icon.X color="white" size={15} className="icon-navbar" /> Cancelar</Button>
                                  <Button color="primary" size="lg" onClick={() => encaminharChamado()}><Icon.Save color="white" size={15} className="icon-navbar" /> Salvar</Button>
                                </ModalFooter>
                              </Modal>
                            {/* /Modal Encaminhar*/}

                            {/* Modal Finalizar*/}
                              <Modal isOpen={modalFinalizarChamado} toggle={toggleModalFinalizarChamado}>
                                <ModalBody>
                                  <Label>Finalizar chamado </Label>
                                  <Input type="textarea" name="new_obs" id="new_obs" rows={6}  onChange={ e => settextoFinalizarChamado(e.target.value) } value={textoFinalizarChamado}  />
                                </ModalBody>
                                <ModalFooter>
                                  <Button color="info" size="lg" onClick={toggleModalFinalizarChamado}><Icon.X color="white" size={15} className="icon-navbar" /> Cancelar</Button>
                                  <Button color="primary" size="lg" onClick={() => chamadoFinalizar()}><Icon.Save color="white" size={15} className="icon-navbar" /> Salvar</Button>
                                </ModalFooter>
                              </Modal>
                            {/* /Modal Finalizar*/}
                          </div> 
    }
  }

  if (chamado.status == 4) {
    status.badge = 'info';
    status.text = 'Aguardando Aprovação';
    aprovedButtons = <div className="div_buttons_aproved">
                      <Button color="danger" className="aprovedButton" size="sm" onClick={() => toggleModalReprovado()}><Icon.ThumbsDown color="white" size={15} className="icon-navbar" /> Reprovar</Button>
                      <Button color="primary" className="aprovedButton" size="sm" onClick={() => chamadoAprovado()}><Icon.ThumbsUp color="white" size={15} className="icon-navbar" /> Aprovar</Button> 
                      {/* Modal Reprovado*/}
                        <Modal isOpen={modalReprovado} toggle={toggleModalReprovado}>
                          <ModalBody>
                            <Label>Reprovar chamado </Label>
                            <Input type="textarea" name="new_obs" id="new_obs" rows={6}  onChange={ e => settextoReprovado(e.target.value) } value={textoReprovado}  />
                          </ModalBody>
                          <ModalFooter>
                            <Button color="info" size="lg" onClick={toggleModalReprovado}><Icon.X color="white" size={15} className="icon-navbar" /> Cancelar</Button>
                            <Button color="primary" size="lg" onClick={() => chamadoReprovado()}><Icon.Save color="white" size={15} className="icon-navbar" /> Salvar</Button>
                          </ModalFooter>
                        </Modal>
                      {/* /Modal Reprovado*/}
                     </div> 
                     
    buttonsModalDesc = <div>
                        <ListGroup>
                          <ListGroupItem tag="g" onClick={toggleModalAlterarPrazo} style={{ color: '#559CDF' }}>
                            Alterar Prazo
                          </ListGroupItem>
                          <ListGroupItem tag="c" onClick={toggleModalEncaminhar} style={{ color: '#559CDF' }}>
                            Encaminhar
                          </ListGroupItem>
                          <ListGroupItem tag="d" onClick={removerChamado} style={{ color: '#559CDF' }}>
                            Excluir
                          </ListGroupItem>
                        </ListGroup>

                        {/* Modal Alterar Prazo*/}
                          <Modal isOpen={modalAltPra} toggle={toggleModalAlterarPrazo}>
                                <ModalBody>
                                  <Label>Alterar Prazo </Label>
                                  <Input
                                    type="date"
                                    name="date"
                                    id="prazo"
                                    placeholder="XX/XX/XXXX"
                                    onChange={ e => setprazoChamado(e.target.value) } value={prazoChamado} 
                                  />
                                </ModalBody>
                                <ModalFooter>
                                  <Button color="info" size="lg" onClick={toggleModalAlterarPrazo}><Icon.X color="white" size={15} className="icon-navbar" /> Cancelar</Button>
                                  <Button color="primary" size="lg" onClick={() => alterarPrazo()}><Icon.Save color="white" size={15} className="icon-navbar" /> Salvar</Button>
                                </ModalFooter>
                              </Modal>
                        {/* /Modal Alterar Prazo*/}

                        {/* Modal Encaminhar*/}
                          <Modal isOpen={modalEncaminhar} toggle={toggleModalEncaminhar} >
                            <ModalBody>
                              <FormGroup>
                                <Label for="departamento">Departamento</Label>
                                <Input type="select" name="select" id="departamento" onChange={ (e) => { loadAtendentes(e.target.value) } }>
                                  <option selected disabled>Selecione</option>
                                  {optionsDepartamentos}
                                </Input>
                              </FormGroup>
                              <FormGroup>
                                <Label for="atendente">Atendente</Label>
                                <Input type="select" name="select" id="atendente" onChange={(e) => { selectAtendente(e.target.value) }}>
                                  <option selected disabled>Selecione</option>
                                  {optionsAtendentes}
                                </Input>
                              </FormGroup>
                              <FormGroup>
                                <Label for="exampleDate">Prazo</Label>
                                <Input
                                  type="date"
                                  name="date"
                                  id="prazoEncaminhar"
                                  placeholder="XX/XX/XXXX"
                                  onChange={ e => setPrazoEncaminhar(e.target.value) } value={prazoEncaminhar} 
                                />
                              </FormGroup>
                              <FormGroup>
                                <Label>Descrição</Label>
                                <Input type="textarea" name="encaminharDescricao" id="encaminharDescricao" placeholder="Aa..." rows="4" onChange={ e => setencaminharDescricao(e.target.value) } value={encaminharDescricao} />
                              </FormGroup>
                              <FormGroup>
                                <Button colo="primary" size="lg" block onClick={toggleFile}>
                                  <Icon.Image color="white" size={25} className="icon-navbar" /> Anexar Imagens
                                </Button>
                              </FormGroup>
                              
                              {/* <Row>
                                { listAnexos }
                              </Row> */}
                              <Row>
                                { listFiles }
                              </Row>

                              {/* Anexar Arquivos */}
                              <Modal isOpen={modalFile} toggle={toggleFile} backdrop="static">
                                <ModalHeader toggle={toggleFile}>Anexar Arquivos</ModalHeader>
                                <ModalBody>
                                  <Label for="anexarArquivo">PNG, JPG, JPEG, PDF, DOC...</Label>
                                  <FileBase64
                                    multiple={ true }
                                    onDone={ (e) => setFiles(e) } />
                                    {/* <hr></hr> */}
                                    {/* <Button color="danger" size="lg" block onClick={toggleCamera}>
                                      <Icon.Camera color="white" size={20} className="icon-navbar" /> Tirar foto
                                    </Button> */}
                                </ModalBody>
                                <ModalFooter>
                                  <Button color="secondary" onClick={() => { cancelFiles() }}>Cancelar</Button>
                                  <Button color="primary" onClick={() => { uploadFiles() }}>Pronto</Button>
                                </ModalFooter>
                              </Modal>
                              {/* /Anexar Arquivos */}
                            </ModalBody>
                            <ModalFooter>
                              <Button color="info" size="lg" onClick={toggleModalEncaminhar}><Icon.X color="white" size={15} className="icon-navbar" /> Cancelar</Button>
                              <Button color="primary" size="lg" onClick={() => encaminharChamado()}><Icon.Save color="white" size={15} className="icon-navbar" /> Salvar</Button>
                            </ModalFooter>
                          </Modal>
                        {/* /Modal Encaminhar*/}
                      </div>
  }
  
  if (chamado.status == 5) {
    status.badge = 'danger';
    status.text = 'Encerrado'
    aprovedButtons = '';
  }

  let crm_sga;

  if (chamado.registro_id != null && chamado.registro_cliente_cpf != null ){

    let crm_url = 'https://crm.ciebe.com.br/'
    let prm_url = 'https://sga.ciebe.com.br/'
    let prm_button

    if(chamado.registro_cliente_cpf){
      let url = prm_url + 'clientes/alterar/' + chamado.cliente.id
      if (chamado.vendas > 1) {
        url = prm_url + 'vendas/visualizar/' + chamado.vendas.id
      }
  
      prm_button = <Button color="primary" size="sm" onClick={() => abrirLink(url)}>Abrir financeiro</Button>
    }else{
      prm_button = <div></div>
    }

    crm_url = crm_url + "contatos/registro/"+chamado.registro_contato_id

    crm_sga = <div>
                <FormGroup>
                  <Label><strong>Esse chamado é vinculado em um registro de contato do CRM!</strong></Label>
                    <Button color="primary" size="sm" onClick={() => abrirLink(crm_url) } style={{ marginRight: 5 }} >Abrir registro de contato</Button>
                  {prm_button}
                  <hr></hr>
                </FormGroup>
              </div>

    
  }else{
    crm_sga = <div></div>
  }

  function abrirLink(url) {
    window.open(url, '_blank');
  }

    return (
        <div>
          <Sidebar />
          <h5 className="display-4" style={{ fontSize: 28, textAlign: 'center', backgroundColor: '#e8eaed', paddingBottom: '2%',  paddingTop: '2%'}}>
            Chamado #{chamado.id}
          </h5>
          <div>
            <Container>
            <SweetAlert
              show={showToastr}
              title={infoToastr.title}
              text={infoToastr.content}
              confirmButtonText={infoToastr.textConfirm}
              onConfirm={() =>  console.log('Done')}
            />
            {aprovedButtons}
            <Nav tabs>
              <NavItem>
                <NavLink                  
                  className={classnames({ active: activeTab === '1' })}
                  onClick={() => { toggle('1'); }}
                >
                  <Icon.File color="grey" size={15}  className="icon-navbar" />
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '2' })}
                  onClick={() => { toggle('2'); }}
                >
                  <Icon.MessageCircle color="grey" size={15}  className="icon-navbar" />
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '3' })}
                  onClick={() => { toggle('3'); }}
                >
                  <Icon.Paperclip color="grey" size={15}  className="icon-navbar" />
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '4' })}
                  onClick={() => { toggle('4'); }}
                >
                  <Icon.RefreshCcw color="grey" size={15}  className="icon-navbar" />
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '5' })}
                  onClick={() => { toggle('5'); }}
                >
                  <Icon.Users color="grey" size={15}  className="icon-navbar" />
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
              {/* Descrição */}
              <TabPane tabId="1">
                <Row>
                  <Col sm="12">
                    <Container style={{ marginTop: '4%' }}>
              
                      <FormGroup>
                        <Label for="titulo"><strong>Assunto</strong></Label>
                        <p> {chamado.titulo} </p> 
                        <hr></hr>
                      </FormGroup>

                      <FormGroup>
                        <Label for="descricao"><strong>Descrição</strong></Label>
                        <div>
                          {describe}
                        </div>
                        <hr></hr>
                      </FormGroup>

                      {crm_sga}

                      <FormGroup>
                        <Label for="autor"><strong>Autor</strong></Label>
                        <p> {chamado.usuario_nome} </p> 
                        <hr></hr>
                      </FormGroup>

                      <FormGroup>
                        <Label for="prazo"><strong>Prazo</strong></Label>
                        <p> {chamado.prazo} </p> 
                        <hr></hr>
                      </FormGroup>

                      <FormGroup>
                        <Label for="prazo"><strong>Status</strong></Label>
                        <p> <Badge color={status.badge} pill>{status.text}</Badge> </p> 
                        <hr></hr>
                      </FormGroup>
                      
                    </Container>
                  </Col>
                </Row>

                { chamado.status != 5 && 
                <FloatingButton.Container>
                  <Button color="primary" className="buttonModal" onClick={toggleModalDesc} >
                     <Icon.Edit2 color="white" size={25} className="icon-navbar" />
                  </Button>
                </FloatingButton.Container>                
                }

                {/* Modal Descrição*/}
                <Modal isOpen={modalDesc} toggle={toggleModalDesc}>
                  <ModalBody>
                    {buttonsModalDesc}
                  </ModalBody>
                  <ModalFooter>
                    <Button color="info" size="lg" block onClick={toggleModalDesc}>Cancelar</Button>
                  </ModalFooter>
                </Modal>
                {/* /Modal Descrição*/}
              </TabPane>
              {/* /Descrição*/}

              {/* Observação */}
              <TabPane tabId="2">
                <Row>
                  <div className="cardObs">
                    { listObs }
                  </div>
                </Row>

                <FloatingButton.Container>
                  <Button color="primary" className="buttonModal" onClick={toggleModalObs} >
                     <Icon.Plus color="white" size={25} className="icon-navbar" />
                  </Button>
                </FloatingButton.Container>

                {/* Modal Observação*/}
                <Modal isOpen={modalObs} toggle={toggleModalObs}>
                  <ModalBody>
                    <Label>Nova Observação</Label>
                    <Input type="textarea" name="new_obs" id="new_obs" rows={12}  onChange={ e => settextoObservacao(e.target.value) } value={textoObservacao} placeholder="Aa..." />
                  </ModalBody>
                  <ModalFooter>
                    <Button color="info" size="lg" onClick={toggleModalObs}><Icon.X color="white" size={15} className="icon-navbar" /> Cancelar</Button>
                    <Button color="primary" size="lg" onClick={() => enviarObs()}><Icon.Save color="white" size={15} className="icon-navbar" /> Salvar</Button>
                  </ModalFooter>
                </Modal>
                {/* /Modal Observação*/}

                {/* Modal Chat*/}
                <Modal isOpen={modalChat} toggle={toggleModalChat} >
                  <ModalBody>
                    <Label>Comentários</Label>
                    <FormGroup >
                      {chatMessages}
                    </FormGroup>

                    <InputGroup>
                      <Input type="textarea" name="new_Chat" id="new_Chat" rows={12}  onChange={ e => settextoChat(e.target.value) } value={textoChat} placeholder="Aa..." />
                      {/* <InputGroupAddon addonType="append" onClick={() => enviarMsg()}>
                        <InputGroupText style={{ backgroundColor: '#007BFF', color: '#ffffff' }}><Icon.Send size={15} /></InputGroupText>
                      </InputGroupAddon> */}
                    </InputGroup>
                  </ModalBody>
                  <ModalFooter>
                    <Button color="info" size="lg" onClick={toggleModalChat}><Icon.X color="white" size={15} className="icon-navbar" /> Fechar</Button>
                    <Button color="primary" size="lg" onClick={() => enviarMsg()}><Icon.Send color="white" size={15} className="icon-navbar" /> Enviar</Button>
                  </ModalFooter>
                </Modal>
                {/* /Modal Chat*/}

              </TabPane>
              {/* /Observação */}

              {/* Anexos */}
              <TabPane tabId="3">
                <Row>
                  { listAnexos }
                </Row>

                {/* Modal Renomear*/}
                <Modal isOpen={modalRename} toggle={toggleRename}>
                  <ModalBody>
                    <Label>Renomear </Label>
                    <FormGroup>
                      <Input type="text" name="new_name_file" id="new_name_file" onChange={e => setNewNameFile(e.target.value)} value={newNameFile} placeholder={infoRenameFile.name} />
                    </FormGroup>
                  </ModalBody>
                  <ModalFooter>
                    <Button color="info" size="lg" onClick={toggleRename}><Icon.X color="white" size={15} className="icon-navbar" /> Cancelar</Button>
                    <Button color="primary" size="lg" onClick={() => renomearArquivo()}><Icon.Save color="white" size={15} className="icon-navbar" /> Salvar</Button>
                  </ModalFooter>
                </Modal>
                {/* /Modal Renomear*/}

                {/* <Row>
                  { listFiles }
                </Row> */}

                <FloatingButton.Container>
                  <Button color="primary" className="buttonModal" onClick={toggleFile} >
                     <Icon.Plus color="white" size={25} className="icon-navbar" />
                  </Button>
                </FloatingButton.Container>

                {/* Anexar Arquivos */}
                <Modal isOpen={modalFile} toggle={toggleFile} backdrop="static" >
                  <ModalHeader toggle={toggleFile}>Anexar Arquivos</ModalHeader>
                  <ModalBody>
                    <Label for="anexarArquivo">PNG, JPG, JPEG, PDF, DOC...</Label>
                    <FileBase64
                      multiple={ true }
                      onDone={ (e) => setFiles(e) } />
                      {/* <hr></hr> */}
                      {/* <Button color="danger" size="lg" block onClick={toggleCamera}>
                        <Icon.Camera color="white" size={20} className="icon-navbar" /> Tirar foto
                      </Button> */}
                  </ModalBody>
                  <ModalFooter>
                    <Button color="secondary" onClick={() => { cancelFiles() }}>Cancelar</Button>
                    <Button color="primary" onClick={() => { uploadFiles() }}>Pronto</Button>
                  </ModalFooter>
                </Modal>
                {/* /Anexar Arquivos */}

                {/* Tirar Foto */}
                <Modal isOpen={modalCamera} toggle={toggleCamera} >
                  <ModalHeader toggle={toggleCamera}>Foto</ModalHeader>
                  <ModalBody>
                    <Camera sendFile={ uploadImage } />
                  </ModalBody>
                  <ModalFooter>
                    <Button color="secondary" onClick={toggleCamera}>Fechar</Button>
                  </ModalFooter>
                </Modal>
                {/* /Tirar Foto */}


              </TabPane>
              {/* /Anexos */}

              {/* Histórico */}
              <TabPane tabId="4">
                <Row>
                  { listHistorico }
                </Row>
              </TabPane>
              {/* /Histórico */}

              {/* Visualizadores */}
              <TabPane tabId="5">
                <Row>
                  { listVisualizadores }
                </Row>
                <FloatingButton.Container>
                  <Button color="primary" className="buttonModal" onClick={toggleModalVis} >
                     <Icon.Plus color="white" size={25} className="icon-navbar" />
                  </Button>
                </FloatingButton.Container>

                {/* Modal Visualizadores*/}
                <Modal isOpen={modalVis} toggle={toggleModalVis}>
                  <ModalHeader>
                    <Label>Adicionar Colaborador novo</Label>
                  </ModalHeader>
                  <ModalBody>
                    <Select2
                      multiple
                      value={visualizadoresSelecionados}
                      style={{ width: '100%'}}
                      data={visualizadoresadd}
                      defaultValue={selDefaultValueVisualizadores}
                      onSelect={selHandleOnChange}
                      options={{
                        placeholder: 'Pesquisar colaborador...',
                      }}
                    />
                  </ModalBody>
                  <ModalFooter>
                    <Button color="info" size="lg" onClick={toggleModalVis}><Icon.X color="white" size={15} className="icon-navbar" /> Cancelar</Button>
                    <Button color="primary" size="lg" onClick={() => sendViewers()}><Icon.Save color="white" size={15} className="icon-navbar" /> Salvar</Button>
                  </ModalFooter>
                </Modal>
                {/* /Modal Visualizadores*/}

                {/* Modal Finalizar Chamado*/}
                <Modal isOpen={modalFin} toggle={toggleModalFinalizar}>
                  <ModalBody>
                    <Label>Deseja Finalizar Chamado</Label>
                    <FormGroup>
                      <Input type="textarea" name="textFinalizar" id="textFinaliza" onChange={ e => settextoFinalizar(e.target.value) } value={textoFinalizar} />
                    </FormGroup>
                  </ModalBody>
                  <ModalFooter>
                    <Button color="info" size="lg" onClick={toggleModalFinalizar}><Icon.X color="white" size={15} className="icon-navbar" /> Cancelar</Button>
                    <Button color="primary" size="lg" onClick={() => sendFinalizarChamado()}><Icon.Save color="white" size={15} className="icon-navbar" /> Finalizar</Button>
                  </ModalFooter>
                </Modal>
                {/* /Modal Finalizar Chamado*/}
              </TabPane>
              {/* /Visualizadores */}

            </TabContent>

          
            </Container>
          </div> 
        </div>
    );
}

