import React, { Component, useState, useEffect } from 'react';
import { InputGroup, InputGroupAddon, Button, Input, Badge, Container, Alert, Media, Table } from 'reactstrap';
import Sidebar from '../../components/side_menu';
import { Link } from 'react-router-dom';
import * as Icon from 'react-feather';
import api from '../../services/api';
import  './index.css';


export default function Pesquisar(props) {

    const [search, setSearch] = useState([]);
    const [message, setMessage] = useState('');
    const [chamados, setChamados] = useState([]);

    useEffect(() => {
      console.log('uEffect', chamados)
    })

    const onChangeHandler = event => {
      setSearch(event.target.value);
    };

    const viewChamado = chamadoId => {
      localStorage.setItem('chamadoId', chamadoId)
      window.location.href = '/visualizar_chamado'
    }

    const loadPesquisa = async () => {
      const token = localStorage.getItem('token');
      const user_login = JSON.parse(localStorage.getItem('user_login'));

      if (search == '') {
        setMessage('Campo pesquisa é obrigatório.')
      }else{
        setMessage('')
      }

      // console.log('user_login', user_login.id)

      api.post('/chamados-pwa/pesquisar', {
        usuario_id: user_login.id,
        pesquisa: search.trim(),
        super_usuario: user_login.super_usuario
      }, {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${token}`
        }
      }).then(resposta => {
        console.log('inputPesquisa', search)
        console.log('respostaPesquisa', resposta.data.pesquisas)
        setChamados(resposta.data.pesquisas)
      });
    }
    

    const listItems = chamados.map((chamado, i) =>{
        let chamado_foto

      if (chamado.usuario_foto == 'N/D') {
        chamado_foto = "/upload/funcionarios/empty.png"
      } else {
        chamado_foto = chamado.usuario_foto
      }

        let avatar = 'http://erp.ciebe.com.br' + chamado_foto;
        
        let status = []
           
        if(chamado.status == 1){
            status.badge = 'primary';
            status.text = 'Aguardando Atendimento'
        }
        if(chamado.status == 3){
            status.badge = 'success';
            status.text = 'Em Atendimento'
        }
        if(chamado.status == 4){
            status.badge = 'info';
            status.text = 'Aguardando Aprovação'
        }
        if(chamado.status == 5){
            status.badge = 'danger';
            status.text = 'Encerrado'
        }

        let title = ''
        if(chamado.visualizado == 1){
          title = <strong>{chamado.titulo}</strong>
        }else{
          title = chamado.titulo
        }

        return  <tr>
            <th scope="row">
                <Media left>
                     <img src={avatar} className="image_avatar" width="64" height="64" />
                </Media>
            </th>
            <td style={{color: "black"}}>
              <Link to={'visualizar_chamado'} onClick={() => { viewChamado(chamado.id) }}>
                {title}<br></br>
                Autor: <i>{chamado.usuario_nome}</i><br></br>
                Status: <Badge color={status.badge} pill>{status.text}</Badge>
              </Link>
            </td>
        </tr>
    });

    let showMessage
    if(message != ''){
        showMessage = <div style={{marginTop: '4%'}}><Alert color="danger"> {message} </Alert></div>
    }else{
      showMessage = ''
    }
  
  return (
    <div>
      <Sidebar />
        <h5 className="display-4" style={{ fontSize: 28, textAlign: 'center', backgroundColor: '#e8eaed', marginBottom: '0%'}}>
          Pesquisar
        </h5>
        <Container>
          {showMessage}
          <InputGroup style={{  marginTop: '5%' }}>
            <Input onChange={onChangeHandler} />
            <InputGroupAddon addonType="append">
              <Button color="secondary" onClick={loadPesquisa} >Pesquisar</Button>
            </InputGroupAddon>
          </InputGroup>
          
        </Container>
         <Table size="sm table-adjust" style={{ marginTop: '5%'}}>
            <thead>
            <tr>
                <th>#</th>
                <th>
                    Chamado
                </th>
                <th></th>
            </tr>
            </thead>
            <tbody>
              {listItems}
            </tbody>
        </Table>
    </div>
  );

  
}
