import React from 'react';
import { BrowserRouter, Switch, Route, useParams } from 'react-router-dom';
import Login from './pages/login';
import LoginNotification from './pages/login_notification';
import ListaChamado from './pages/lista_chamado';
import ListaColaboracoes from './pages/lista_colaboracoes';
import ListaEncerrados from './pages/lista_encerrados';
import ListaSolicitacoes from './pages/lista_solicitacoes';
import NovoChamado from './pages/novo_chamado';
import Pesquisar from './pages/pesquisar';
import VisualizarChamado from './pages/visualizar_chamado';
import Notificacoes from './pages/notificacoes';
import Logout from './pages/logout';

// let token = localStorage.getItem('token');
// const response = await api.post('usuarios/login', {
//     email: this.state.email,
//     password: this.state.password
// });
// console.log('teste', response.data)
// console.log('aqui', token)

// window.location.href = "http://www.w3schools.com";

const Routes = () => (
    <BrowserRouter>
        <Switch>
            <Route exact path="/" component={Login} />
            <Route path="/login/:id" component={LoginNotification} children={redirectchamado} />
            <Route path="/pesquisar" component={Pesquisar} />
            <Route path="/novo_chamado" component={NovoChamado} />
            <Route path="/lista_chamado" component={ListaChamado} />
            <Route path="/lista_solicitacoes" component={ListaSolicitacoes} />
            <Route path="/visualizar_chamado" component={VisualizarChamado} />
            <Route path="/lista_colaboracoes" component={ListaColaboracoes} />
            <Route path="/lista_encerrados" component={ListaEncerrados} />
            <Route path="/notificacoes" component={Notificacoes} />
            <Route path="/logout" component={Logout} />
        </Switch>
    </BrowserRouter>
)

function redirectchamado(){
    const path = window.location.pathname
    const call_id = Number(path.replace('/login/', ''))
    console.log('call_id: ', typeof call_id)
    if (typeof call_id === 'number') {
        localStorage.setItem('chamadoId', call_id)
        window.location.href = '/visualizar_chamado'
    }
}

export default Routes;