import React, { Component, useState, useEffect } from 'react';
import Sidebar from '../../components/side_menu';
import {  Table, Badge, Media   } from 'reactstrap';
import { Link } from 'react-router-dom';
import api from '../../services/api';
import { pedirPermissaoParaReceberNotificacoes } from '../../push-notification';
import  './style.css';

export default function ListaChamado(props) {

    const [chamados, setChamados] = useState([]);

    useEffect(() => {
        loadChamados();
        loadNotificacoes();
        pedirPermissaoParaReceberNotificacoes();
    }, [])

    const loadChamados = () => {

        const token = localStorage.getItem('token');
        const user_login = JSON.parse(localStorage.getItem('user_login'));

        api.post('/chamados-pwa/minhas-tarefas', {
            funcionario_id: user_login.id,
        }, {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`
            }
        }).then(resposta => {
            setChamados(resposta.data.chamados)
        });
        
    }

    const loadNotificacoes = () => {

        const token = localStorage.getItem('token');
        const user_login = JSON.parse(localStorage.getItem('user_login'));

        api.post('/chamados-pwa/notificacao-total', {
            usuario_id: user_login.id,
        }, {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`
            }
        }).then(resposta => {
            console.log('status', resposta)
            localStorage.setItem('notificacao_total', resposta.data)
        });
        
    }
    
    let listItems = []
    if (chamados != null) {
        listItems = chamados.map((chamado, i) =>{
            let chamado_foto
    
            if (chamado.foto == 'N/D') {
                chamado_foto = "/upload/funcionarios/empty.png"
            } else {
                chamado_foto = chamado.foto
            }
    
            let avatar = 'http://erp.ciebe.com.br' + chamado_foto;
            
            let status = []
               
            if(chamado.status == 1){
                status.badge = 'primary';
                status.text = 'Aguardando Atendimento'
            }
            if(chamado.status == 3){
                status.badge = 'success';
                status.text = 'Em Atendimento'
            }
            if(chamado.status == 4){
                status.badge = 'info';
                status.text = 'Aguardando Aprovação'
            }
            if(chamado.status == 5){
                status.badge = 'danger';
                status.text = 'Encerrado'
            }
    
            let title = ''
            if(chamado.visualizado == 1){
                title = chamado.titulo
            }else{
                title = <strong>{chamado.titulo}</strong>
            }
    
    
            return  <tr>
                        <td scope="row">
                            <Media left>
                                <img src={avatar} className="image_avatar" width="64" height="64" />
                            </Media>
                        </td>
                        
                        <td style={{color: "black"}}>
                            <Link to={'visualizar_chamado'} onClick={() => { viewChamado(chamado.id) }}>
                                {title}<br></br>
                                Autor: <i>{chamado.nome}</i><br></br>
                                Status: <Badge color={status.badge} pill>{status.text}</Badge>
                            </Link>
                        </td>
                    </tr>
        });
    }
        

    const viewChamado = chamadoId => {
        localStorage.setItem('chamadoId', chamadoId)
        window.location.href = '/visualizar_chamado'
    }


    return (
        <div>
            <Sidebar />
            <h5 className="display-4" style={{ fontSize: 28, textAlign: 'center', backgroundColor: '#e8eaed', marginBottom: '0%'}}>
                Minhas Tarefas
            </h5>
            
            <Table size="sm table-adjust" style={{ marginTop: '0%', width: '100%'}}>
                <thead>
                <tr>
                    <th>#</th>
                    <th>
                        Chamado
                    </th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                    { listItems }
                </tbody>
            </Table>
        </div>
    );
}
