import axios from 'axios';

// let baseURL = 'http://localhost/sisac/api/v1';

let baseURL = 'https://sisac.ciebe.com.br/api/v1/';


const api = axios.create({
    baseURL: baseURL, 
    headers: {
        Accept: 'application/json',
    }
});

export default api;