import React, { Component, useState, useEffect } from 'react';
import {
  Badge
} from 'reactstrap';
// Icones da aplicação
import * as Icon from 'react-feather';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  NavbarText
} from 'reactstrap';
import SweetAlert from 'sweetalert2-react';
import Statusbar from '../statusbar';
import { Link } from 'react-router-dom';
import api from '../../services/api';
import './index.css';


const Sidebar = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [notificacaoTotal, setnotificacaoTotal] = useState('');
  const toggle = () => setIsOpen(!isOpen);

  const [showToastr, setShowToastr] = useState(false);
  const toggleToastr = () => setShowToastr(!showToastr);
  const [infoToastr, setinfoToastr] = useState([]);

  useEffect(() => {
    loadNotificacoes();
  }, [])

  let naoLido = ''
  if (window.location.pathname == '/visualizar_chamado'){
    naoLido = <div className="bell_button" onClick={ () => naoVisualizado() }>
                <Icon.Mail color="white" size={30} className="" />
              </div>
  }else{
    naoLido = ''
  }

  function naoVisualizado(){
    const token = localStorage.getItem('token');
    const user_login = JSON.parse(localStorage.getItem('user_login'));
    const chamadoId = localStorage.getItem('chamadoId');
    const info = []

    info.title = 'Chamado não lido'
    info.content = 'Enviando...'
    info.textConfirm = 'Aguarde'
    setinfoToastr(info)
    toggleToastr()

    api.post('/chamados-pwa/marcar-naolido', {
      usuario_id: user_login.id,
      id: chamadoId
    }, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
      }
    }).then(resposta => {
      console.log('status', resposta)

      window.location.href = '/lista_chamado'
    });
  }

  const loadNotificacoes = () => {
    
    if (!localStorage.getItem('user_login')) {
      window.location.href = '/'
    }

    const token = localStorage.getItem('token');
    const user_login = JSON.parse(localStorage.getItem('user_login'));


    api.post('/chamados-pwa/notificacao-total', {
        usuario_id: user_login.id,
    }, {
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
        }
    }).then(resposta => {
        console.log('status', resposta)
        setnotificacaoTotal( resposta.data)
    });
        
  }

  const counterNotifications = () => {
    if (notificacaoTotal == 0){
      // console.log('status resposta s: ', notificacaoTotal)
      return ''
    }else{
      // return <Badge color="danger" className="badge_bell_button">{notificacaoTotal}</Badge>
      console.log('status resposta n: ', notificacaoTotal)
    }
    console.log('status resposta def: ', notificacaoTotal)
  }

  return (
    <div>
      <Statusbar />

      <SweetAlert
        show={showToastr}
        title={infoToastr.title}
        text={infoToastr.content}
        confirmButtonText={infoToastr.textConfirm}
        onConfirm={() =>  console.log('Done')}
      />

      <Navbar className="header" dark expand="md">
        <NavbarToggler onClick={toggle} style={{color:'white'}} />
        <Collapse className="collapse" isOpen={isOpen} navbar>
          <Nav className="mr-auto" navbar>
            <NavItem>
              <Link to={'novo_chamado'}>
                <NavLink className="nav-link">
                  <Icon.Plus color="white" size={20}  className="icon-navbar" />&nbsp;
                  Abrir Chamado
                </NavLink>
              </Link>
            </NavItem>
            <NavItem >
              <Link to={'pesquisar'}>
                <NavLink className="nav-link">
                  <Icon.Search color="white" size={20}  className="icon-navbar" />&nbsp;
                  Pesquisar
                </NavLink>
              </Link>
            </NavItem>
            <NavItem >
              <Link to={'lista_chamado'}>
                <NavLink className="nav-link">
                  <Icon.Server color="white" size={20}  className="icon-navbar" />&nbsp;
                  Minhas Tarefas
                </NavLink>
              </Link>
            </NavItem>
            <NavItem >
              <Link to={'lista_solicitacoes'}>
                <NavLink className="nav-link">
                  <Icon.Server color="white" size={20}  className="icon-navbar" />&nbsp;
                  Minhas Solicitações
                </NavLink>
              </Link>
            </NavItem>
            <NavItem >
              <Link to={'lista_colaboracoes'}>
                <NavLink className="nav-link">
                  <Icon.Server color="white" size={20}  className="icon-navbar" />&nbsp;
                  Minhas Colaborações
                </NavLink>
              </Link>
            </NavItem>
            <NavItem >
              <Link to={'lista_encerrados'}>
                <NavLink className="nav-link">
                  <Icon.Check color="white" size={20}  className="icon-navbar" />&nbsp;
                  Encerrados
                </NavLink>
              </Link>
            </NavItem>
            <NavItem >
              <Link to={'logout'} >
                <NavLink className="nav-link">
                  <Icon.LogOut color="white" size={20}  className="icon-navbar" />&nbsp;
                  Sair
                </NavLink>
              </Link>
            </NavItem>
          </Nav>
          <NavbarText><br></br></NavbarText>
        </Collapse>
        <NavbarBrand href="/">
          <img src="./logo_sisac.png" className="logo_header" alt="SisAC" />
          {/* <img src="https://sisac.ciebe.com.br/img/logo.png?1560522924" className="logo_header" alt="SisAC" /> */}
        </NavbarBrand>
        <div className="bell_button">
          <Link to={'notificacoes'}>
            
            { notificacaoTotal > 0 &&
              <Badge color="danger" className="badge_bell_button">{notificacaoTotal}</Badge>
            }
            <Icon.Bell color="white" size={30} className="" />
          </Link>
        </div>
        {naoLido}

      </Navbar>
    </div>
  );
}

export default Sidebar;