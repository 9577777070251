import React, { Component } from 'react';
import {
    Container,
    Row,
    Col,
    Form,
    FormGroup,
    Label, 
    Input,
    Button,
    Alert
} from 'reactstrap';
import * as Icon from 'react-feather';
import api from '../../services/api';
import { pedirPermissaoParaReceberNotificacoes, notificationFirebase } from '../../push-notification';
import './style.css';

export default class Login extends Component {
    state = {
        user_login: [],
        token: '',
        email: '',
        password: '',
        message: '',
        checkResponse: []
    }

    componentDidMount(){
        this.consultLogin()
        setTimeout(() => {
            pedirPermissaoParaReceberNotificacoes()
            notificationFirebase()
        }, 1000);
    }
    
    

    consultLogin() {
        const token = localStorage.getItem('token')
        const user_login = localStorage.getItem('user_login');
        console.log('user_login.id', user_login)

        if (user_login != null) {

            api.post('/chamados-pwa/minhas-tarefas', {
                funcionario_id: user_login.id,
            }, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${token}`
                }
            }).then(resposta => {

                if (resposta.status == 200) {
                    // alert('Aqui')
                    const path = window.location.pathname
                    const call_id = Number(path.replace('/', ''))
                    console.log('call_id: ', typeof call_id)
                    if (typeof call_id === 'number') {
                        localStorage.setItem('chamadoId', call_id)
                        // window.location.href = '/visualizar_chamado'
                    }else{
                        // window.location.href = '/'

                    }
                }
            });

        }
    }

    inputEmail = event => {
        this.setState({
            email: event.target.value
        })
    }

    inputPassword = event => {
        this.setState({
            password: event.target.value
        })
    }

    setMessage = event => {
        this.setState({
            message: event.target.value
        })
    }

    loadChamados = async () => {
        if (this.state.email == ''){
            return this.setState({message: 'Campo email é obrigatório.'})
        }
        
        if (this.state.password == ''){
            return this.setState({message: 'Campo senha é obrigatório.'})
        }

        const response = await api.post('usuarios-pwa/login', {
            email: this.state.email,
            password: this.state.password,
            uid: localStorage.getItem('uid'),
            token: localStorage.getItem('token_firebase'),
            device: 'pwa'
        });

        this.setState({
            checkResponse: response.data
        })

        if (this.state.checkResponse.message) {
             return this.setState({message: response.data.message[0]})
        }

        if (this.state.checkResponse.data.token) {
            this.setState({ user_login: response.data.data.usuario})
            this.setState({ token: response.data.data.token})
    
            localStorage.setItem('user_login', JSON.stringify(response.data.data.usuario))
            localStorage.setItem('token', response.data.data.token)
            window.location.href = '/lista_chamado'
        }else{
            return this.setState({
                message: response.data.message[0]
            })
        }

    }
    

    render(){
        let message;

        if(this.state.message != ''){
            message = <div><Alert color="danger"> {this.state.message} </Alert></div>
        }

        return (
                <Container>
                    <Col style={{ justifyContent: 'center', marginTop: '20%', alignItems: 'center' }}>
                        <Row style={{ justifyContent: 'center' }}>
                            <img src="https://sisac.ciebe.com.br/img/sisac_logo_pwa.png" className="logo_header" />
                        </Row>
                        <Row style={{ justifyContent: 'center', marginTop: 10 }}>
                            <Form style ={{ width: '100%' }}>
                                {message}
                                <FormGroup>
                                    <Label for="email">Email</Label>
                                    <Input type="email" name="email" id="email" onChange={this.inputEmail} value={this.state.email} placeholder="Digite seu email" />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="senha">Senha</Label>
                                    <Input type="password" name="senha" id="senha" onChange={this.inputPassword} value={this.state.password}  placeholder="Digite sua senha" />
                                </FormGroup>
                                <div style={{ textAlign:'center' }}>
                                    {/* <Link to={'lista_chamado'}> */}
                                    <Button onClick={ this.loadChamados}  >
                                        <Icon.LogIn color="white" size={15} className="" />&nbsp;
                                        Entrar
                                    </Button>
                                    {/* </Link> */}
                                </div>
                            </Form>
                            
                        </Row>
                    </Col>
                </Container>
        );
    }
}