import firebase from 'firebase';

firebase.initializeApp({
    apiKey: "AIzaSyC3mWfvesy8rlXLFmsudq-7xNeyu3053P8",
    authDomain: "sisac-ec7e6.firebaseapp.com",
    databaseURL: "https://sisac-ec7e6.firebaseio.com",
    projectId: "sisac-ec7e6",
    storageBucket: "sisac-ec7e6.appspot.com",
    messagingSenderId: "783940047829",
    appId: "1:783940047829:web:5de5bedeef24a05902a35c",
    measurementId: "G-52NCXQBCP4"
});

export const inicializarFirebase = () => {
   
    navigator.serviceWorker
        .register('./firebase-sw.js')
        .then((registration) => {
            firebase.messaging().useServiceWorker(registration);
        });

    firebase.auth().signInAnonymously().then( resp => {
        localStorage.setItem('uid', resp.user.uid)
    })
    
}

export const notificationFirebase = async () => {
    console.log('Carregou')
    firebase.messaging().onMessage(payload => {
        console.log("Message received. ", payload);
    });
}

export const pedirPermissaoParaReceberNotificacoes = async () => {
    try {
        const messaging = firebase.messaging();
        await messaging.requestPermission();
        const token = await messaging.getToken();
        localStorage.setItem('token_firebase', token);
        console.log('token do usuário:', token);
        return token;
    } catch (error) {
        console.error('ERROR pedirPermissaoParaReceberNotificacoes ERROR', error);
    }
}


