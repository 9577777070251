import React, { Component, useState, useEffect } from 'react';
import {
  Button, Badge, Container,
  Form, FormGroup, Label,
  Input, FormText, Row,
  Col, Modal, ModalHeader, 
  ModalBody, ModalFooter, CustomInput,
  CardText, Card, CardTitle
} from 'reactstrap';
import FileBase64 from 'react-file-base64';
import Sidebar from '../../components/side_menu';
import api from '../../services/api';
import * as FloatingButton from 'react-floating-action-button';
import {Camera} from '../../components/camera';
import { Fab, FabButtons, FabButton } from 'framework7-react';
import * as Icon from 'react-feather';
import SweetAlert from 'sweetalert2-react';
import  './index.css';

export default function NovoChamado(props) {

  const [optionsDepartamento, setoptionsDepartamento] = useState([]);
  const [selectedDepartamento, setselectedDepartamento] = useState([]);
  const [optionsAtendente, setoptionsAtendente] = useState([]);
  const [selectedAtendente, setselectedAtendente] = useState([]);
  const [textoDescricao, settextoDescricao] = useState('');
  const [prazo, setPrazo] = useState('');
  const [debug, setDebug] = useState([]);
  const [assunto, setAssunto] = useState('');
  const [modalFile, setModalFile] = useState(false);
  const [modalCamera, setModalCamera] = useState(false);
  const [files, setFiles] = useState([]);
  const [alertSweet, setalertSweet] = useState(false);

  const toggleFile = () => setModalFile(!modalFile);
  const toggleCamera = () => setModalCamera(!modalCamera);

  useEffect(() => {
    loadDepartamentos();
    // loadAtendentes()
  }, [])

  const loadDepartamentos = () => {

    const token = localStorage.getItem('token');
    const user_login = JSON.parse(localStorage.getItem('user_login'));

    // console.log('user_login', user_login.id)

    api.post('/chamados-pwa/pega-departamento', {
      id: user_login.id,
    }, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
      }
    }).then(resposta => {
      console.log(resposta.data)
      setoptionsDepartamento(resposta.data)
    });
  }

  const loadAtendentes = (id) => {

    const token = localStorage.getItem('token');
    const user_login = JSON.parse(localStorage.getItem('user_login'));
    console.log('loadAtendentes', id)

    api.post('/chamados/pega-atendente', {
      id: id,
    }, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
      }
    }).then(resposta => {
      setselectedDepartamento(id)
      console.log(resposta.data)
      setoptionsAtendente(resposta.data)
    });
  }

  const selectAtendente = (id) => {
    setselectedAtendente(id)
  }

  const getFiles = (file: FileList) => {
    console.log('setFiles aqui:', file)
    setFiles(file)
  }
  
  const uploadFiles = () => {
    toggleFile()
  }

  const cancelFiles = () => {
    setFiles([])
    toggleFile()
  }

  const enviarChamado = () => {
    const token = localStorage.getItem('token');
    const user_login = JSON.parse(localStorage.getItem('user_login'));
    
    if (selectedDepartamento == ''){
      return alert('Necessário escolher um departamento')
    }
    
    if (selectedAtendente == '') {
      return alert('Necessário escolher um atendente')
    }
    
    if (prazo == '') {
      return alert('Campo prazo não pode estar vazio')
    }
    
    if (assunto == '') {
      return alert('Campo assunto não pode estar vazio')
    }
    
    if (textoDescricao == '') {
      return alert('Campo descrição não pode estar vazio')
    }

  
    let prz = prazo.split('-')
    setPrazo(prz[1]+"/" + prz[2] + "/" + prz[0])

    api.post('/chamados/abre-chamado', {
      departamento_id: selectedDepartamento,
      atendente_id: selectedAtendente,
      prazo: prz[1] + "/" + prz[2] + "/" + prz[0],
      titulo: assunto,
      descricao: textoDescricao,
      images: files,
      usuario_id: user_login.id
    }, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
      }
    }).then(resposta => {
      setalertSweet(!alertSweet)
      console.log(resposta.data)
    });

    console.log('enviarChamado')
  }

  const redirecionarChamado = () => {
    window.location.href = '/lista_chamado'
  }
  
  const removeFile = (filePosition) => {
    console.log('files', files)
    files.splice(filePosition, 1);
    // console.log('newFiles', newFiles)
    setFiles([])

    setTimeout(() => {
      setFiles(files)
    }, 50);

    console.log('After remove', files)
  }

  const optionsDepartamentos = optionsDepartamento.map((option, i) => {
    return <option value={option.id} key={option.id}>{option.nome}</option>
  });

  const optionsAtendentes = optionsAtendente.map((option, i) => {
    return <option value={option.id} key={option.id}>{option.nome}</option>
  });

  let listFiles = files.map((file, i) =>{
   
    let anexo_icone = <Icon.File color="black" size={25}  className="icon-navbar" />

    if(file.type == 'application/png' || file.type == 'application/jpg' || file.type == 'application/jpeg'){
      anexo_icone = <Icon.Image color="black" size={25}  className="icon-navbar" />
    }

    if(file.type == 'application/pdf'|| file.type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"){
      anexo_icone = <Icon.FileText color="black" size={25}  className="icon-navbar" />
    }

    let extension = file.type.split('/')
    if(extension[0] == "video"){
      alert("Arquivos de video não são permitidos!")
      setTimeout(() => {
        setFiles([])
      }, 50);
    }

    console.log('type', file.type)

    return  <Col sm="6">
              <Card body style={{ marginTop: 10 }}>
                <CardTitle>
                  <p>
                    {anexo_icone} {file.name}
                  </p>
                </CardTitle>
                
                <Button color="danger" onClick={ () => { removeFile(i) }} style={{ marginRight: 3, fontSize: 15, padding: 5}}>
                  <Icon.Trash color="white" size={20}  className="icon-navbar" /> Remover
                </Button>
                
              </Card>
            </Col>
  });

  // Upload to local seaweedFS instance
  const uploadImage = async file => {
    const formData = new FormData();
    formData.append('Content-Type', 'multipart/form-data');
    formData.append('file', file);
  };
  
  return (
    <div>
      <Sidebar />
        <h5 className="display-4" style={{ fontSize: 28, textAlign: 'center', backgroundColor: '#e8eaed', marginBottom: '0%'}}>
          Novo Chamado
        </h5>
        <Container style={{ marginTop: '4%' }}>
          <Row>
            <Col sm="12">
              <form encType="multipart/form-data">
                <FormGroup>
                  <Label for="departamento">Departamento</Label>
                  <Input type="select" name="select" id="departamento" onChange={ (e) => { loadAtendentes(e.target.value) } }>
                    <option selected disabled>Selecione</option>
                    {optionsDepartamentos}
                  </Input>
                </FormGroup>

                <FormGroup>
                  <Label for="atendente">Atendente</Label>
                  <Input type="select" name="select" id="atendente" onChange={(e) => { selectAtendente(e.target.value) }}>
                    <option selected disabled>Selecione</option>
                    {optionsAtendentes}
                  </Input>
                </FormGroup>

                <FormGroup>
                  <Label for="exampleDate">Prazo</Label>
                  <Input
                    type="date"
                    name="date"
                    id="prazo"
                    placeholder="XX/XX/XXXX"
                    onChange={ e => setPrazo(e.target.value) } value={prazo} 
                  />
                </FormGroup>

                <FormGroup>
                  <Label for="assunto">Assunto</Label>
                <Input type="text" name="assunto" id="assunto" placeholder="Aa..." onChange={e => setAssunto(e.target.value)} value={assunto} />
                </FormGroup>

                <FormGroup>
                  <Label>Descrição</Label>
                  <Input type="textarea" name="textDescricao" id="textDescricao" placeholder="Aa..." rows="4" onChange={ e => settextoDescricao(e.target.value) } value={textoDescricao} />
                </FormGroup>

                <Button color="info" size="lg" block onClick={toggleFile}>
                  <Icon.Paperclip color="white" size={25} className="icon-navbar" /> Anexar Arquivos
                </Button>

                {/* Anexar Arquivos */}
                <Modal isOpen={modalFile} toggle={toggleFile} >
                  <ModalHeader toggle={toggleFile}>Anexar Arquivos</ModalHeader>
                  <ModalBody>
                    <Label for="anexarArquivo">PNG, JPG, JPEG, PDF, DOC...</Label>
                    <FileBase64
                      multiple={ true }
                      onDone={ (e) => setFiles(e) } />
                      {/* <hr></hr> */}
                      {/* <Button color="danger" size="lg" block onClick={toggleCamera}>
                        <Icon.Camera color="white" size={20} className="icon-navbar" /> Tirar foto
                      </Button> */}
                  </ModalBody>
                  <ModalFooter>
                    <Button color="secondary" onClick={() => { cancelFiles() }}>Cancelar</Button>
                    <Button color="primary" onClick={() => { uploadFiles() }}>Pronto</Button>
                  </ModalFooter>
                </Modal>
                {/* /Anexar Arquivos */}

                {/* Tirar Foto */}
                <Modal isOpen={modalCamera} toggle={toggleCamera} >
                  <ModalHeader toggle={toggleCamera}>Foto</ModalHeader>
                  <ModalBody>
                    <Camera sendFile={ uploadImage } />
                  </ModalBody>
                  <ModalFooter>
                    <Button color="secondary" onClick={toggleCamera}>Fechar</Button>
                  </ModalFooter>
                </Modal>
                {/* /Tirar Foto */}

                <FormGroup>
                  
                </FormGroup>
                </form>  

                {listFiles}
            </Col>
          </Row>
           
        </Container>
        <Row>

        {/* <FloatingButton.Container className="pullLeft">
            <FloatingButton.Link href="#"
              tooltip="Create note link"
              icon="far fa-sticky-note">
            </FloatingButton.Link>

            <FloatingButton.Link href="#"
              tooltip="Add user link"
              icon="fas fa-user-plus"
              className = "fab-item btn btn-link btn-xs text-white">
            </FloatingButton.Link>

          <Button color="info" className="buttonModal"  >
              <Icon.Paperclip color="white" size={25} className="icon-navbar" />
          </Button>
        </FloatingButton.Container> */}
        

          <FloatingButton.Container className="buttonFab">
            <Button color="primary" className="buttonModal"  onClick={ () => { enviarChamado()}}>
                <Icon.Check color="white" size={25} className="icon-navbar" />
            </Button>
          </FloatingButton.Container>

          {/* SweetAlert2 */}
          <SweetAlert
            show={alertSweet}
            title="Sucesso"
            text="Chamado salvo com sucesso"
            onConfirm={() => { redirecionarChamado() }}
          />
          {/* /SweetAlert2 */}

        </Row>
          
    </div>
  );

  
}
